import React from 'react';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/customeSelectStyles';
import { useSelector } from 'react-redux';
import { selectState } from '../store/slices/themeSlice';
import Select, { MultiValue, StylesConfig } from 'react-select';
import makeAnimated from 'react-select/animated';

export interface Option {
  label: string;
  value: string;
}
const animatedComponents = makeAnimated();
// Define the props interface for the MultiSelect component
interface MultiSelectProps {
  name: string;
  value: Option[]; // Array to store multiple selected values
  options: Option[];
  onChange: (value: Option[]) => void; // Update to handle an array of values
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  defaultValue?: Option[];
  error?: string; 
}

const CustomMultiSelect: React.FC<MultiSelectProps> = ({
  name,
  value,
  options,
  onChange,
  disabled = false,
  placeholder = "Select options", // Default placeholder value
  label,
  defaultValue,
  error
}) => {
  const { theme } = useSelector(selectState);
  const classes = useCustomStyles(customStyles, theme);

  const colourStyles: StylesConfig<Option, true> = {
    control: (styles) => ({ ...styles, backgroundColor: 'white', width: '100%' }),
    option: (styles) => ({...styles})
       //  
  };
  

  const handleChange = (selectedOptions: MultiValue<Option>) => {
    onChange(selectedOptions as Option[]); // Cast MultiValue to Option[]
  };


  return (
    <div className={classes?.customSelectContainer}> {/* Add container wrapper */}
      {label && <label className={classes?.customSelectLabel}>{label}</label>} {/* Render label if provided */}
    <Select
      name={name}
      value={value}
      onChange={handleChange}
      isDisabled={disabled}
      placeholder={placeholder}
      closeMenuOnSelect={false}
      components={animatedComponents}
      defaultValue={defaultValue}
      isMulti
      styles={colourStyles}
      options={options}
      />
      {/* Conditionally render error message */}
      {error && <p className={classes?.error}>{error}</p>}
      </div>
  );
};

export default CustomMultiSelect;
