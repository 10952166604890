import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { updatePasswordAction } from "../store/actions/authActions";
import { selectState, setError } from "../store/slices/authSlice";
import "../assets/styles/index.css";
import CustomButton from "../components/CustomButton";
import useCustomStyles from "../hooks/useCustomStyles";
import { customStyles } from "../assets/styles/login";
import LogInSignUpInput from "../components/SignInInput";
import { toast } from "react-toastify";

function UpdatePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState<string | null>(null);
  const [newPasswordError, setNewPasswordError] = useState<string | null>(null);

  const dispatch = useDispatch<AppDispatch>();
  const { error, loading } = useSelector(selectState);
  const { theme } = useSelector((state: RootState) => state.theme_slice);
  const classes = useCustomStyles(customStyles, theme);

  const validate = () => {
    let isValid = true;

    if (!currentPassword) {
      setCurrentPasswordError("Current password is required.");
      isValid = false;
    } else {
      setCurrentPasswordError(null);
    }

    if (!newPassword) {
      setNewPasswordError("New password is required.");
      isValid = false;
    } else if (newPassword.length < 6) {
      setNewPasswordError("New password must be at least 6 characters.");
      isValid = false;
    } else {
      setNewPasswordError(null);
    }

    return isValid;
  };

  const handleUpdatePassword = async () => {
    if (validate()) {
      try {
        await dispatch(updatePasswordAction({ currentPassword, newPassword })).unwrap();
        //alert("Password updated successfully!");
        //navigate("/profile"); // Redirect to the profile page or another appropriate route
        toast.success("Password updated successfully!");
      } catch (err) {
        console.error("Password update error:", err);
      }
    }
  };

  return (
    <div className={`${classes?.container} ${classes?.containerMarginTop}`}>
      <h2
        style={{
          fontSize: "24px",
          color: "#333333",
          fontWeight: "bold",
          marginTop: 8,
          marginBottom: 2,
        }}
      >
        Update Password
      </h2>
      <p
        style={{
          textAlign: "center",
          fontSize: "16px",
          color: "#333333",
          fontWeight: "normal",
          marginTop: 2,
        }}
      >
        Enter your current password and a new password to update
      </p>
      <div>
        <LogInSignUpInput
          placeholder={"Current Password"}
          value={currentPassword}
          onChangeText={(value) => { setCurrentPassword(value); setCurrentPasswordError(null); dispatch(setError(undefined))}}
          secureTextEntry={true}
          showToggle={true}
        />
        {currentPasswordError && <p className={classes?.error}>{currentPasswordError}</p>}
        
        <LogInSignUpInput
          placeholder={"New Password"}
          value={newPassword}
          onChangeText={(value) => { setNewPassword(value); setNewPasswordError(null);dispatch(setError(undefined)) }}
          secureTextEntry={true}
          showToggle={true}
        />
        {newPasswordError && <p className={classes?.error}>{newPasswordError}</p>}
        
        <CustomButton
          title={loading ? "Updating..." : "Update Password"}
          name="updatePassword"
          handleClick={handleUpdatePassword}
          disabled={loading}
          loading={loading}
        />
        
        {error && <p className={classes?.error}>{error.toString()}</p>}
      </div>
    </div>
  );
}

export default UpdatePassword;
