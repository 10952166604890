import { useMemo } from 'react';
import { css } from '@emotion/css';
import { Theme } from '../types';
import { CSSObject } from '@emotion/react';

export type Styles = { 
  [key: string]: Record<string, CSSObject | number | string > 
} | ((theme: Theme) => { 
  [key: string]: Record<string, CSSObject | string | number> 
});

const useCustomStyles = (styles: Styles, theme: Theme) => {
  // Use useMemo to compute the classes only when styles or theme change
  const classes = useMemo(() => {
    const evaluatedStyles = typeof styles === 'function' ? styles(theme) : styles;
    const generated: { [key: string]: string } = {};

    for (const key in evaluatedStyles) {
      generated[key] = css(evaluatedStyles[key]); // Using Emotion's css function
    }

    return generated;
  }, [styles, theme]); // Dependencies: styles and theme

  return classes;
};

export default useCustomStyles;
