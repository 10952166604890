import React, { createContext, PropsWithChildren, useContext, useState } from 'react';
import en from '../locales/en.json';

type Translation = typeof en; // Define a type based on the structure of the translations

// const translations: Record<string, Translation> = { en, fr };
const translations: Record<string, Translation> = { en };

interface TranslationContextProps {
  t: typeof en;
  toggleLanguage: () => void;
}

const TranslationContext = createContext<TranslationContextProps | undefined>(undefined);

export const TranslationProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [language, setLanguage] = useState('en');
  const [t, setCurrentTranslations] = useState(translations.en);

  const toggleLanguage = () => {
    // const newLanguage =    language === 'en' ;? 'fr' : 'en';
    const newLanguage = 'en';
    setLanguage(newLanguage);
    setCurrentTranslations(translations[newLanguage]);
  };

  return (
    <TranslationContext.Provider value={{ t, toggleLanguage }}>
      {children}
    </TranslationContext.Provider>
  );
};

export const useTranslation = (): TranslationContextProps => {
  const context = useContext(TranslationContext);
  if (!context) {
    throw new Error('useTranslation must be used within a TranslationProvider');
  }
  return context;
};
