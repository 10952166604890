import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store'; // Import your store type
import { profileStyles } from '../assets/styles/profileStyles'; 
import useCustomStyles from '../hooks/useCustomStyles';
import { ReactComponent as MockProfile } from '../assets/images/profile_icon_sopher.svg';
import { capitalizeFirstLetter } from '../utils';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as SopherProfileIcon } from '../assets/images/Profile Icon.svg';
import { ReactComponent as ElisyanProfileIcon } from '../assets/images/Elisyan Profile Icon .svg';


const ProfileSection = () => {
  const { theme } = useSelector((state: RootState) => state.theme_slice);
  const { user } = useSelector((state: RootState) => state.auth_slice); // Get the user data from the auth slice
  const classes = useCustomStyles(profileStyles, theme);
  const navigate = useNavigate()
  const profileUrl = user?.profile_url || undefined;
  const userName = user?.user_name || 'Anonymous';
  const userRole = user?.roles?.join('/') || 'Guest';

  return (
    <div className={classes.profileSection} onClick={()=>navigate('/profile')}>
      <div className={classes.pictureContainer}> 
        {user?.profile_url ? 
        <img
            src={profileUrl}
            alt="Profile"
            className={classes.profileImage}
        /> : (user?.business && ['sopher', 'sopher-test'].includes(user?.business.name))?<SopherProfileIcon width={65} height={65} /> : <ElisyanProfileIcon width={65} height={65} />
        }
      </div> 
      <div className={classes.profileDetails}>
        <p className={classes.userName}>{userName}</p>
        <p className={classes.userRole}>({capitalizeFirstLetter(userRole)})</p>
      </div>
    </div>
  );
};

export default ProfileSection;
