import { Theme } from "../../types";
import { CSSObject } from "@emotion/react";

export const customStyles = (
  theme: Theme
): { [key: string]: Record<string, CSSObject | string | number> } => ({
  customButton: {
    border: "none",
    color: theme.colorSchema.buttons.secondary.text,
    padding: "14px 20px",
    textAlign: "center",
    display: "inline-block",
    fontSize: "1rem",
    marginTop: "10px",
    cursor: "pointer",
    borderRadius: "25px",
    width: "100%",
    //maxWidth: "600px",
    fontWeight: 700,
    // boxShadow: `0px 0px 10px 0px ${theme.colorSchema.colors.mainText}`,
    transition: "background 0.3s ease, color 0.3s ease",
    background: `linear-gradient(to right, ${theme.colorSchema.buttons.primary.background[0]}, ${theme.colorSchema.buttons.primary.background[1]})`,
    "&:hover": {
      background: `linear-gradient(to right, ${theme.colorSchema.buttons.primary.background[1]}, ${theme.colorSchema.buttons.primary.background[0]})`,
    },
    "&:disabled": {
      cursor: "not-allowed",
      opacity: 0.7,
      // background: theme.colorSchema.buttons.primary.text,
    },
  },
  loadingButton: {
    border: "none",
    textAlign: "center",
    display: "inline-block",
    marginTop: "10px",
    cursor: "pointer",
    borderRadius: "15px",
    width: "100%",
    maxWidth: "600px",
    boxShadow: "2px 2px 2px #898a8d",
    background: "linear-gradient(to right, #1D3AAB, #880098)",
    maxHeight: "43px",
  },
  loadingIcon: {
    width: "43px",
    height: "43px",
    margin: 0,
  },
});
