  export const DefaultSopherConfigFile = {
    name: 'sopher',
    light: {
        colorSchema: {
            inputs: {
                secondary: {
                    background: '#DBC7AC',
                    text: '#FFFFFF',
                    outline: '#DBC7AC',
                    icon: '#FFFFFF',
                },
                plus: {
                    background: ['#6B7A8E','#6B7A8E'],
                    text: '#FFE300',
                    outline: '#6B7A8E',
                    icon: '#FFE300',
                },
                primary: {
                    background: ['#FFFFFF','#FFFFFF'],
                    text: '#6B7A8E',
                    outline: '#6B7A8E',
                    icon: '#FFFFFF',
                },
            },
            buttons: {
                secondary: {
                    background: '#DBC7AC',
                    text: '#FFFFFF',
                    outline: '#DBC7AC',
                    icon: '#FFFFFF',
                },
                plus: {
                    background: ['#6B7A8E','#6B7A8E'],
                    text: '#FFE300',
                    outline: '#6B7A8E',
                    icon: '#FFE300',
                },
                primary: {
                    background: ['#6B7A8E','#6B7A8E'],
                    text: '#FFE998',
                    outline: '#6B7A8E',
                    icon: '#FFFFFF',
                },
            },
            colors: {
                secondary: '#6B7A8E',
                progressBarDashboard: ['#FFE998', '#57370D'],
                secondaryBackground: '#F6F6F6',
                mainBackground: '#FFFFFF',
                profilePictureStroke: '#FFE998',
                text: '#949494',
                mainText: '#6B7A8E',
                primary: '#595959',
                secondaryText: '#DBC7AC',
                thirdText: '#FFE998',
                inputBackground: '#FFFFFF',
                thirdBackground: '#E7E6E6',
            },
        },
    },
    dark: {
        colorSchema: {
            inputs: {
                secondary: {
                    background: '#DBC7AC',
                    text: '#FFFFFF',
                    outline: '#DBC7AC',
                    icon: '#FFFFFF',
                },
                plus: {
                    background: ['#6B7A8E','#6B7A8E'],
                    text: '#FFE300',
                    outline: '#6B7A8E',
                    icon: '#FFE300',
                },
                primary: {
                    background: ['#FFFFFF','#FFFFFF'],
                    text: '#6B7A8E',
                    outline: '#6B7A8E',
                    icon: '#FFFFFF',
                },
            },
            buttons: {
                secondary: {
                    background: '#DBC7AC',
                    text: '#6B7A8E',
                    outline: '#DBC7AC',
                    icon: '#FFFFFF',
                },
                plus: {
                    background: ['#6B7A8E','#6B7A8E'],
                    text: '#FFE300',
                    outline: '#6B7A8E',
                    icon: '#FFE300',
                },
                primary: {
                    background:[ '#FFE998','#FFE998'],
                    text: '#6B7A8E',
                    outline: '#6B7A8E',
                    icon: '#FFFFFF',
                },
            },
            colors: {
                secondary: '#6B7A8E',
                progressBarDashboard: ['#FFE998', '#57370D'],
                secondaryBackground: '#6B7A8E',
                mainBackground: '#6B7A8E',
                profilePictureStroke: '#FFE998',
                text: '#FFFFFF',
                mainText: '#FFFFFF',
                primary: '#595959',
                secondaryText: '#6B6B6B',//'#DBC7AC',
                thirdText: '#FFE998',
                inputBackground: '#FFFFFF',
                thirdBackground: '#E7E6E6',
            },
        },
    },
};
