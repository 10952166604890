import { collection, onSnapshot } from 'firebase/firestore';
import { firestore } from '../Firebase';  //Your initialized firestore instance
import { AccessCodeType, ContractType } from '../store/actions/accessCodeActions';

// Assuming you have a contracts collection
const contractsRef = collection(firestore, 'contracts');

export const listenToLicenceCountUpdates = (
    updateContractInState: (contracts: ContractType[]) => void  // Update parameter to accept an array
  ) => {
    const unsubscribe = onSnapshot(contractsRef, (querySnapshot) => {
      // Map through the documents to construct the contracts array
      const contracts: ContractType[] = querySnapshot.docs.map(doc => ({
        business_client: doc.data().owner.id,  // Assuming owner.id is the field for business_client
        contract_ref: doc.data().reference,
        useLimit: doc.data().use_limit,
        expire_at: doc.data().expire_at,
        licence_count: doc.data().licence_count,
        id: doc.id,  // Include the document ID
      }));
  
      // Pass the full contracts array to the update function
      updateContractInState(contracts);
    });
  
    return unsubscribe;  // Return the unsubscribe function
  };
  

// Assuming you have a licenses collection
const licencesRef = collection(firestore, 'access_codes');

export const listenToLicenceValidatedUpdates = (
    updateLicenceInState: (accessCode: AccessCodeType) => void
  ) => {
    const unsubscribe = onSnapshot(licencesRef, (querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const licenceData = doc.data() as AccessCodeType; // Cast to AccessCodeType
        const accessCode: AccessCodeType = {
          ...licenceData,
          id: doc.id, // Include the document ID
        };
  
        // Pass the full accessCode object to the update function
        updateLicenceInState(accessCode);
      });
    });
     return unsubscribe;
};
