import React, { useState } from 'react';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/CustomDisplayStyles';
import { useSelector } from 'react-redux';
import { selectState } from '../store/slices/themeSlice';
import CopyToClipboard from 'react-copy-to-clipboard';
import { ReactComponent as Copy } from '../assets/images/copy-svgrepo-com.svg';
import { ReactComponent as Copied } from '../assets/images/clipboard-text-svgrepo-com.svg';

// Define the props interface for the new component
interface CustomDisplayProps {
  label: string;
  value: string | number | undefined | Date;
  allowCopy?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode; // Add icon prop of type ReactNode
  handleIconClick?: () => void; // Add icon click handler
}

const CustomDisplay: React.FC<CustomDisplayProps> = ({ label, value, allowCopy, disabled, icon, handleIconClick }) => {
  const { theme } = useSelector(selectState);
  const classes = useCustomStyles(customStyles, theme);
  const [isCopied, setIsCopied] = useState(false);

  const handleCopy = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1000); // Reset thecopied state after 2 seconds
  };
  // Convert Date to a readable string format
  const displayValue = value instanceof Date ? value.toDateString() : value;

  return (
    <div className={`${classes?.customInputContainer }`}>
      {label && <label className={classes?.customInputLabel}>{label}</label>} {/* Render label */}
      <div className={`${classes?.customInputDisplay} ${disabled ? classes.disabledInput : ''}`}>
        { displayValue }
           {allowCopy &&(<><CopyToClipboard text={displayValue? displayValue.toString() : ''} onCopy={handleCopy}>
            {!isCopied ? <Copied width={20} height={20} /> : <div className={classes.flexComponent}><Copy width={20} height={20} /><p className={classes.NoMargin} >copied!</p></div>}
          </CopyToClipboard>
        </>)
        }
        {icon && (
          <p onClick={handleIconClick} className={classes.NoMargin} >
            {icon}
          </p>
        )}
        </div>
    </div>
  );
};

export default CustomDisplay;
