import { createSlice, PayloadAction } from '@reduxjs/toolkit'; //importing create slice
import { RootState } from '../store';
import { sendNotification, getUserCredentialBackup, addToWaitlist } from '../actions/notificationActions';


// define initialState

type Notification = {
  title?: string;
  text: string;
  from: string;
  sentTime: string;
  topic?: string | null;
  id: string;
  opened?: boolean;
};

type Waitlist = {
  email: string;
  name: string;
  sentence: string | undefined;
  referral_code?: string | undefined;
  id: string;
  created_at?: Date | string | undefined;
  updated_at?: Date | string | undefined;
}

type State = {
  deviceToken?: string | null;
  deviceTokens: string[] | null;
  notifications: Notification[] | null;
  notOppened: number; 
  loading: boolean;
  error: string | undefined;
  notifTypes: string[];
  waitlist : Waitlist[] | [];
};

const initialState: State = {
  waitlist: [],
  deviceToken: null,
  notifTypes: ['appUpdates',
    'alerts',
    'messages',
    'tips']
    
  ,
  deviceTokens: [
    'c2eBtkUKSl-o-nmgvnHZcD:APA91bHU7riiO6cRQQV0UiZOrd-DzFVwqmcff7-WgIefJ2XtCBaUTL1vtgbrvL-IZejPJRcLHuawj69ftWIqBOd2kSdLjXHBrkWiISSlzKjbtgWmmv7b4vOnuChzxLBIU7f3F2C_q-yc',
    'f_nP4pF6T1iBuK9f5VK_S5:APA91bGq1lemva1XYQ0I5287GXeAl_f6zyol-_y4V2FT_fc1CSxaZMjwLLpJGcSqKif3fKg8sRK7r0Yi410kWZvge-n21N_SW--ZM73U0T6Fds1R80zt3FuSS_XuntNzx1V3QgKGjnxa',
    'feYF1tu5RFu4j_1C8njFol:APA91bE-_DBLyq4NpNR5Nn97F8wqcIFDAJT4E14u5CKBjJLN13Zb3ExmcE3buocqSfVxf5Fp982DXK-31D1rsPDErjznCilnNRqg2cE_Kihagp3Em69I5e5-AQylv4g_KfhB-tsuiTL4',
    'f3hxDtulSzmkVZB7fssbpm:APA91bHdkxBDUo9vjKnjYk2AltG0IxnAzog0I66vUI4qHp2Nsj7VUi6Ncc46Q5I4-IIO9PQF3AqGdfDyJMA8vXHaHikU5otJh2S6eNpc15MT6PpWithbXGO8Jk64CRkur9fbnoyCVEU8',
    'c4RI1hwKQEWzlsxaNP_jl8:APA91bEx-oOMqY_pgCxEltlMsi3nh8MDNai2Gq2bfroEvJSArzO4etehyn5j1uyq3c4JwlKC8rBMExYJlCGibprHIO5F7eYbJSxA1eWLlfmic7Mi52w1q6qcGVbvavcpG57TPhms_1GB',
    'f938ji47ReW_COheDxTXS4:APA91bGZxjxIytzP9w0-QufLftZqpsfJJ69JjMM8JaR3oQfM75vw93goQY4jxOpNzh9cj6grkfgERLj8dPtnL0mN1rHRGuKM4Ge4iOwrWauDU1PJAbBJJCVKpiKBWPhzvzY-0FS7YLYV',
    'fAR7QD89SKu7HKsRYN1Vur:APA91bHIiv8CwY9GV-w2CHEElmKtuOn8cbBEw7H2tfRPLXB8h_t-qt-fkgAuxVh4TiIfQ5hacZ4u4hNzeCttd2LVzKh4jj2kk9vVSbzcIcjpiws2JxyfP7vm9h33nBKNnTdbIhA0OAX1',
    'fFsU1hzQR4O-J1wOC7WiNW:APA91bGgQ_jMfx9P7IJw4r1ZX1rLCxE2G10SYGZiOA3InnynIV9ffurZQ02PtwoGtXKt6PrQYy4i_tlig5nY5d128MNx6mrGpH4MCnpOjKrq5qrRr4oUsFZmveo3c2kWX5p3PvmEJaHd',
    'dZbnx6O-QnO7u-RdlQRKtA:APA91bH4Ii_8mfiuGCn6TLhqIlRqhhUNG_IlMxW8hX9HK72b_ZOh0yq8zd6NpfIJbLFBq5mQGyDCOcY1bB02V8B5PEE-kgQTWSW-EAZwA1T9RUFMfbwAtunwMZ8HgGWQ99JsJgEy7edz',
    'e1XVI6FKRHOhnWukF51M2O:APA91bGcQbCgSOnlMr1XadrGsdXwl6XRAte2ZNQx-8FJZBYxwhgEIqeIRkzpQvmijaikX5XO8frNkCXi65-_r7BlMYaksySLgXb14lxDmM8f9sqHtuZSpUxRQrXC12IN6xWh77Jliay6',
    'd7M7igaXTZKsdBcWTL81QB:APA91bFKA-aVygJF8TwG4QVw2SW2xC3aQl6BTBQ_8xXXU9XhebYjv5gWN4HshIFiZcwyRKxysNGjoCuv5g2OZ9QeuF1kovi7yCSGPIsyesSIorfuMPayTNg3t5c_B6CF_XkHEf9vS6Mb',
    'fhPzWKYTQqC3Xl69tPpVmM:APA91bHZNLOFmSLePu5XgFVL297iqWtip4G-5aewZN9q69I7sfUbiOtbHRJ5KnjrCVFpjThL7ZGG8UqLQ-jYjri3d_18rDG4hNoIj-nwSomMiXYmXQ07F9kByKchCEE6JSpcE53K1MT0',
    'dVTjQLmNEUOaqdzyqyYgDB:APA91bHZjnTAV6-4JFuKKMZJbAHgy5G2tuKNWCdvfsFqxDrmTDnZcpzMhsnyqSQ359RKvU1BsqGQiew-Nyy7mYhYG5viw-zToJRx3Sgad7kbGyXH4kMTI1fQS9EyDwgl94IAtnkLwhG9',
    'd_NLBLl5QEinmR_vZc538c:APA91bGTpwoZ_MGGxCOk7XROT8jSfYhd1bPqI41ZZaOpxlQFoQxakXyqHOLfwe-qwuVRMt2dm7heVNfFQKgxw_OtcjXtd3iujMUiGy-kOVNCJE4zwBty7fGThm6gCWEm9uYDEgJ_UQx4'
  ],
  notifications: [
    {
      from: '1055096900240',
      id: '0:1722945491188014%fc0a9ef5fc0a9ef50',
      sentTime: '8/6/2024, 12:58:11 PM',
      text: 'text to test compaign notifications from firebase console ',
      title: 'testing compaign notifications ',
      opened: false,
    },
    {
      from: '1055096900240',
      id: '0:1722945508448650%fc0a9ef5fc0a9ef51',
      sentTime: '8/6/2024, 12:58:28 PM',
      text: 'text to test compaign notifications from firebase console 1',
      title: 'testing compaign notifications 1',
      opened: false,
    },
    {
      from: '1055096900240',
      id: '0:1722945521372240%fc0a9ef5fc0a9ef52',
      sentTime: '8/6/2024, 12:58:41 PM',
      text: 'text to test compaign notifications from firebase console 2',
      title: 'testing compaign notifications 2',
      opened: false,
    },
    {
      from: '1055096900240',
      id: '0:1722945531990018%fc0a9ef5fc0a9ef53',
      sentTime: '8/6/2024, 12:58:51 PM',
      text: 'text to test compaign notifications from firebase console 3',
      title: 'testing compaign notifications 3',
      opened: false,
    },
    {
      from: '1055096900240',
      id: '0:1722945554492562%fc0a9ef5fc0a9ef54',
      sentTime: '8/6/2024, 12:59:14 PM',
      text: 'text to test compaign notifications from firebase console 4',
      title: 'testing compaign notifications 4',
      opened: false,
    },
    {
      from: '1055096900240',
      id: '0:1722945572182195%fc0a9ef5fc0a9ef55',
      sentTime: '8/6/2024, 12:59:32 PM',
      text: 'text to test compaign notifications from firebase console 5',
      title: 'testing compaign notifications 5',
      opened: false,
    },
  ],
  notOppened: 6,
  loading: false,
  error: undefined,
};

const notificationSlice = createSlice({
  name: 'notification_slice',
  initialState,
  reducers: {
    setNotifications: (state: State, action): void => {
      const currentNotifications = state.notifications ?? [];
      state.notifications = [...currentNotifications, action.payload];
      state.notOppened = state.notOppened + 1;
    },
    setNotification: (state: State, action: PayloadAction<Notification>): void => {
      if (state.notifications) {
        console.log('set 0notification', action.payload);
        state.notifications = state.notifications?.map((item) =>
          item.id === action.payload.id ? action.payload : item
        );
        state.notOppened = state.notOppened - 1;
      }
    },
    deleteNotification: (state: State, action: PayloadAction<Notification>): void => {
      if (state.notifications) {
        console.log('delete a notification', action.payload);

        // Filter out the notification to be deleted
        state.notifications = state.notifications.filter((item) => item.id !== action.payload.id);
        /*
        // Logging IDs for debugging
        console.log(
          'state.notifications IDs:',
          state.notifications.map((item) => item.id),
          'new_array IDs:',
          new_array.map((item) => item.id)
        );

        // Update the state with the filtered array
        state.notifications = new_array;
        */
        // Decrement the notOppened counter if the notification wasn't opened
        if (!action.payload.opened) {
          state.notOppened = state.notOppened - 1;
        }
      }
    },
    setToken: (state, action: { type: string; payload: { deviceToken: string } }): void => {
      state.deviceToken = action.payload.deviceToken;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendNotification.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendNotification.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.deviceToken = action.payload;
        console.log('sendNotification from fullfiled extraReducer ::', action.payload);
      }
    });
    builder.addCase(sendNotification.rejected, (state, action) => {
      state.loading = false;
      if (typeof action.payload === 'string'){
        state.error = action.payload;
      } else if(action.payload instanceof Error){
        state.error = action.payload.message;
      }else{
        state.error = 'Unknown error occurred';
      }
    });
    builder.addCase(getUserCredentialBackup.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserCredentialBackup.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        console.log('getUserCredentialBackup from fullfiled extraReducer ::', action.payload);
      }
    });
    builder.addCase(getUserCredentialBackup.rejected, (state, action) => {
      state.loading = false;
      if (typeof action.payload === 'string'){
        state.error = action.payload;
      } else if(action.payload instanceof Error){
        state.error = action.payload.message;
      }else{
        state.error = 'Unknown error occurred';
      }
    });
    builder.addCase(addToWaitlist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addToWaitlist.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.waitlist = [...state.waitlist, action.payload];
        console.log('addTowaitlist fullfiled extraReducer ::', action.payload);
      }
    });
    builder.addCase(addToWaitlist.rejected, (state, action) => {
      state.loading = false;
      if (typeof action.payload === 'string'){
        state.error = action.payload;
      } else if(action.payload instanceof Error){
        state.error = action.payload.message;
      }else{
        state.error = 'Unknown error occurred';
      }
    });
  },
});

export const { setToken, setNotifications, setNotification, deleteNotification } =
  notificationSlice.actions;

export const selectState = (state: RootState) => state.notification_slice;

export default notificationSlice.reducer;