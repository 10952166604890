import { firestore } from "../Firebase";
import { doc, DocumentReference, getDoc } from "firebase/firestore";
import { ContractType } from "../store/actions/accessCodeActions";
  
interface ContractDataType {
    owner: DocumentReference;  // Reference to a business document
    useLimit: number;
    expire_at?: string;
    licence_count: number;
    reference: string;
  }
  
export async function getContractByAccessCodeId(accessCodeId: string): Promise<ContractType | undefined> {
    try {
      // Step 1: Fetch the access code document
      const accessCodeRef = doc(firestore, `access_codes/${accessCodeId}`);
      const accessCodeSnap = await getDoc(accessCodeRef);
  
      if (!accessCodeSnap.exists()) {
        console.log("Access code not found.");
        return undefined;
      }
  
      // Step 2: Get the contract reference from the access code document
      const accessCodeData = accessCodeSnap.data();
      const contractRef = accessCodeData?.contract_ref;
  
      if (!contractRef) {
        console.log("No contract reference found in access code.");
        return undefined;
      }
  
      // Step 3: Fetch the contract document
      const contractSnap = await getDoc(contractRef);
      if (!contractSnap.exists()) {
        console.log("Contract not found.");
        return undefined;
      }
  
      // Specify the contract data structure with ContractDataType
      const contractData = contractSnap.data() as ContractDataType;
  
      // Step 4: Get the business_client ID from contractData.owner
      let business_client: string | undefined = undefined;
      if (contractData?.owner) {
        const ownerSnap = await getDoc(contractData.owner);
        if (ownerSnap.exists()) {
          business_client = ownerSnap.id;
        }
      }
  
      // Step 5: Format the contract data according to ContractType
      const contract: ContractType = {
        business_client,
        contract_ref: contractData.reference,
        useLimit: contractData.useLimit ?? 0,
        expire_at: contractData.expire_at ? new Date(contractData.expire_at) : undefined,
        licence_count: contractData.licence_count ?? 0,
        id: contractSnap.id,
      };
  
      console.log("Contract data:", contract);
      return contract;
    } catch (error) {
      console.error("Error retrieving contract by access code ID:", error);
      return undefined;
    }
  }