import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const customStyles = (theme: Theme): {[key: string]: Record<string, CSSObject | string |number>} => ({

  customInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '1.2rem',
  },
  customInputLabel: {
    marginBottom: '0.2rem',
    fontSize: '16px',
    color: theme.colorSchema.colors.mainText,
  },
  error: {
    color: 'red',
    margin: 0
  },
  customInputDisplay: {
    boxSizing: 'border-box',
    width: '100%',
    padding: '10px',
    borderRadius: '8px',
    fontSize: '1rem',
    cursor: 'text',
    backgroundColor: theme.colorSchema.inputs.primary.background[0],
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
},
disabledInput: {
  backgroundColor:`${theme.colorSchema.colors.thirdBackground} !important`,
},
flexComponent: {
  display: 'flex',
  alignItems: 'center'
},
NoMargin: {
  margin: 0
}
});
