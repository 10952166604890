import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { app, firestore } from "../../Firebase"; // Assuming `firebase.js` is where you initialize Firebase
import { toast } from 'react-toastify';
//import { addDoc, collection, getDocs } from 'firebase/firestore';
//import  { addDoc, collection } from "firebase/firestore";

//const addWaitListEntry = httpsCallable(functions, "addToWaitList");
console.log('app, firestore', app, firestore);
interface sendNotificationArgs {
  message: string | undefined;
  title: string | undefined;
  token: string;
  type: string;
}

interface sendNotificationTopicArgs {
  message: string | undefined;
  title: string | undefined;
  topic: string;
  type: string;
  userId: string | undefined;
}
export interface UserCredentialProvidersBackup {
  userId: string;
  id: string;
  moneyhubId: string;
  yodleeSalt: string;
  yodleeLoginName: string;
  powensId: string;
  powensToken: string;
  profilePic: string;
  createdAt: string;
  updatedAt: string;
  devices: Device[];
}
type Device = {
  token: string;
  deviceId: string;
}

export const getUserCredentialBackup = createAsyncThunk<UserCredentialProvidersBackup, string>(
  'notifications_slice/getUserCredentialBackup',
  async (userId, thunkAPI) => {

  try {
    const res = await fetch(`https://b14h7isg51.execute-api.eu-west-2.amazonaws.com/staging/bckp/account-backup?userId=${userId}`);
    const data = await res.json();
    console.log('userBackup', data)
    const payload = data.response.data as UserCredentialProvidersBackup[];
    return payload[0];
  } catch (e) {
    console.log('Error Fetching getUserCredentialBackup' + e);
    return thunkAPI.rejectWithValue(e);
  }
}
);

interface addToWaitlistArgs {
  email: string | undefined;
  name: string | undefined;
  sentence: string | undefined;
}
interface AddToWaitlistResponse {
    id: string;
    email: string;
    created_at: string;
    name: string;
    sentence: string;
}

// Define the structure for an error response
interface ErrorResponse {
  message: string;
  error: string;
}


export const addToWaitlist = createAsyncThunk< AddToWaitlistResponse | undefined , addToWaitlistArgs >(
  'notifications_slice/addToWaitlist',
  async ({email, name, sentence}, thunkAPI) => {
    //console.log('admin app frontend ', app)
    try {
      console.log('inputs', email, name, sentence)
       //http://127.0.0.1:5001/elisyanwealth/us-central1/addToWaitlist1
      const response = await axios.post('https://us-central1-elisyanwealth.cloudfunctions.net/addToWaitlist1', {
        "email": email,
        "name": name, 
        "sentence": sentence
      }, {
        headers: {
          'Content-Type': 'application/json',
        }});
      console.log('axios', response)
      return response.data
     /*
        const response = await addWaitListEntry(body);
        console.log("Waitlist entry added successfully:", response.data);
     */
     /*const waitlistCollection = collection(firestore, 'users');
     console.log('firestore', firestore)
     console.log('collection:::::::::::::::', waitlistCollection);
     //const newWaitlist = await addDoc(waitlistCollection, {...body});
     const newUser = {appUserId:
      '',
      
      email: 'souadm1@albisai.com',
      
      revenuCatId: '',
      
      
      role:
      'employee'}
     const newWaitlist = await addDoc(waitlistCollection, {...newUser});
     console.log('new waitlist created', newWaitlist)*/
    /* const usersRef = collection(firestore, "users");
    const querySnapshot = await getDocs(usersRef);

    const users = querySnapshot.docs.map(doc => doc.data());
    console.log(users);
     //return newWaitlist;*/
    } catch (e) {
      console.log('-------------- Error register User Device Token' + e);
      return thunkAPI.rejectWithValue(e as ErrorResponse); 
    }
  }
);

export const sendNotification = createAsyncThunk<string | undefined, sendNotificationArgs>(
  'notifications_slice/sendNotification',
  async ({message, title, token, type}, thunkAPI) => {
    // console.log('messaging  get token ', token);
    try {
      const body = {
        "message":{
           "token": token,
           //"condition": `'enabled_push_notification' in topics`, 
           "data": {
            "type": type
           },
           "notification":{
             "body":message,
             "title":title,
             
           }
        }
      }
      
      const response = await axios.post('https://pushnotifications-c5yieuvjca-uc.a.run.app', body, {
        headers: {
          'Content-Type': 'application/json',
         
        }});
      console.log('axios', response)

    } catch (e) {
      console.log('-------------- Error register User Device Token' + e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);


export const sendNotificationByTopic = createAsyncThunk<string | undefined, sendNotificationTopicArgs>(
  'notifications_slice/sendNotification',
  async ({message, title, topic, type, userId}, thunkAPI) => {
    try {
      type NotificationData = {
        type: string;
        body: string | undefined;
        title: string | undefined;
        userID?: string; // userID is optional
      };

      // Initialize the data object with the required fields
      const data: NotificationData = { 
        type: type,
        body: message,
        title: title
      };

      // Conditionally add userID if it exists
      if (userId && userId.length > 10) {
        data.userID = userId;
      }
     
      const body = {
        "message":{
          
          //token: token,
          "topic": topic,
          "data" : data,
          "android": {
            "priority": "high"  // Set priority to high for Android
          },
          "apns": {
            payload: {
                aps: {
                  "content-available": 1,  // Set content-available to true for iOS
                    category: 'post', // A category that's already been created by your app
                    // sound: 'media/kick.wav', // A local sound file you have inside your app's bundle
                    //  ... // any other properties
                },
            },
        },
          
        }
      } //http://127.0.0.1:5001/elisyanwealth/us-central1/pushNotifications
      const response = await axios.post('https://pushnotifications-c5yieuvjca-uc.a.run.app', body, {
        headers: {
          'Content-Type': 'application/json',
        }});
      console.log('axios', response)
      toast.success("Notification Sent successfully!");
    } catch (e) {
      console.log('-------------- Error sending Notification' + e);
      toast.error("Error while sending Notification:");
      return thunkAPI.rejectWithValue(e);
    }
  }
);
