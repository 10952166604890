import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CustomSelect from '../components/CustomSelectInput';
import CustomButton from '../components/CustomButton';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/notificationForm';
import { selectTranslationState } from '../store/slices/languageSlice';
import { selectState } from '../store/slices/themeSlice';
import CustomInput from '../components/CustomInput';
import { AppDispatch, RootState } from '../store/store';
import { addContract, getBusinesses } from '../store/actions/accessCodeActions';


export interface ContractFormType {
  business_client: string | undefined;
  contract_ref: string | undefined;
  useLimit: number | undefined;
  expire_at: string | Date | undefined;
  id?: string | undefined
}

const AddContractForm: React.FC = () => {
  const { t } = useSelector(selectTranslationState);
  const { theme, activeTheme } = useSelector(selectState);
  const { businesses } = useSelector((state: RootState) => state.accessCode_slice)
  const classes = useCustomStyles(customStyles, theme);
  const dispatch = useDispatch<AppDispatch>();

  const handleGetBusinesses = ()=>{
      dispatch(getBusinesses({type: ""}))
    }
    
  useEffect(() => {
    if(!businesses ){
      handleGetBusinesses();
    }
  }, [])
  
  // Validation schema using Yup
  const validationSchema = Yup.object({
    contract_ref: Yup.string().required('Contract reference is required'),
    business_client: Yup.string().required('Please select a business client'),
    useLimit: Yup.number()
      .required('Use limit is required')
      .min(1, 'Use limit must be at least 1'),
    expire_at: Yup.date().required('Expiry date is required'),
  });
  
  // Initialize Formik
  const formik = useFormik<ContractFormType>({
    initialValues: {
      business_client: '', // initial value for business client select
      contract_ref: '',
      useLimit: 1,
      expire_at: undefined,
      id: undefined
    },
    validationSchema,
    onSubmit: (values) => {
       console.log('values', values)
      // Dispatch your code generation action here if needed
      dispatch(addContract(values));
    },
  });

  // Wrapper function to handle form submission from button click
  const handleFormSubmit = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    formik.handleSubmit(); // Trigger formik form submission
  };
  
  return (
    <div className={classes?.container}>
      <div className={classes?.notificationForm}>
        <h1 className={classes?.title}>{t[activeTheme].addContract.form.title}</h1>
        <hr className={classes?.divider}></hr>
        
        <form onSubmit={formik.handleSubmit}>
          {/* Business Client Select */}
          <CustomSelect 
            name="business_client"
            label=" Business Client"
            placeholder='Select Business'
            value={formik.values.business_client}
            options={businesses? businesses.map(item => {return {name: item.name, id: item.id}}): []}
            onChange={(e) => formik.setFieldValue('business_client', e.target.value)} 
            error= {formik.errors.business_client && formik.touched.business_client ?
              formik.errors.business_client : ''
            }
          />
          

          {/* Contract Reference Input */}
          <CustomInput 
            name="contract_ref"
            label= "Contract Reference"
            type="text"
            value={formik.values.contract_ref}
            onChange={formik.handleChange}
            placeholder="Enter Contract Reference"
           error= {formik.errors.contract_ref && formik.touched.contract_ref ?formik.errors.contract_ref : ''
          }
          />
          

          {/* Use Limit Input */}
          <CustomInput 
            name="useLimit"
            label="Use Limit"
            type="number"
            value={formik.values.useLimit}
            onChange={formik.handleChange}
            placeholder="Entre Use Limit"
            error = {formik.errors.useLimit && formik.touched.useLimit ?formik.errors.useLimit : ''}
          />
          

          {/* Expiry Date Input */}
          <CustomInput 
            name="expire_at"
            label="Expiry date"
            type="date"
            value={formik.values.expire_at}
            onChange={formik.handleChange}
            placeholder="Select Expiry date"
            error = {formik.errors.expire_at && formik.touched.expire_at ? formik.errors.expire_at : '' }
          />
          

          {/* Submit Button */}
          <CustomButton
            title="Add"
            name="generateAccessCode"
            handleClick={handleFormSubmit} // Use wrapper for form submission
            loading={false}
          />
        </form>

        {/* Display Generated Code */}
        {/*formik.values.code && <p>Your generated code: {formik.values.code}</p>*/}
      </div>
    </div>
  );
};

export default AddContractForm;
