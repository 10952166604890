import React, { useState, ChangeEvent, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectState } from '../store/slices/themeSlice';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/customeSelectStyles';
import { customStyles as customInputStyles } from '../assets/styles/customInputStyles';

// Define the props for the component
interface SearchableSelectProps {
  options: string[];
  value: string;
  onChange: (value: string) => void;
  placeholder: string;
}

const SearchableSelect: React.FC<SearchableSelectProps> = ({ options, value, onChange, placeholder }) => {
  const { theme } = useSelector(selectState);  
  const classes = useCustomStyles(customStyles, theme);
  const inputClasses = useCustomStyles(customInputStyles, theme);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const filteredOptions = options.filter(option => 
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleOptionClick = (option: string) => {
    onChange(option);
    setIsOpen(false);
  };

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  return (
    <div className="searchable-select" style={{ width: '100%', position: 'relative' }} ref={dropdownRef}>
      <div 
        className={classes.customSelect} 
        style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} 
        onClick={() => setIsOpen(!isOpen)}
      >
        {value || placeholder}
        <span style={{ marginLeft: 'auto', paddingLeft: '10px' }}>▼</span>
      </div>
      {isOpen && (
        <div style={{ position: 'absolute', top: '100%', left: 0, right: 0, zIndex: 1000, backgroundColor: '#fff', border: `2px solid ${theme.colorSchema.buttons.primary.background[1]}`, borderRadius: '15px', maxHeight: '200px', overflowY: 'auto' }}>
          <input 
            type="text" 
            value={searchTerm} 
            onChange={(e: ChangeEvent<HTMLInputElement>) => setSearchTerm(e.target.value)} 
            placeholder="Search..."
            className={inputClasses.customInput}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '15px 15px 0 0',
              borderBottom: `1px solid ${theme.colorSchema.buttons.primary.background[1]}`,
              boxSizing: 'border-box',
            }}
          />
          <div>
            {filteredOptions.map((option, index) => (
              <div 
                key={index} 
                onClick={() => handleOptionClick(option)}
                style={{ padding: '10px', cursor: 'pointer', borderBottom: '1px solid #ddd' }}
              >
                {option}
              </div>
            ))}
            {filteredOptions.length === 0 && (
              <div style={{ padding: '10px', color: '#999' }}>
                No results found
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchableSelect;
