import React, { useEffect, useState } from 'react';
import './App.css';
import SendNotification from './components/SendNotification';
import Login from './views/login';
import { Route, BrowserRouter as Router, Routes, useParams } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectState } from './store/slices/authSlice';
import GenerateReferralCode from './views/accessCodes/accessCodesForm';
import AddToWaitlist from './components/AddToWaitlist';
import Dashboard from './views/dashboard';
import Layout from './views/layout';
import AddContractForm from './views/contracts';
import AccessCodes from './views/accessCodes/accessCodes';
import GenerateLicences from './views/accessCodes/licenceCodeForm';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AccessCode from './views/accessCodes/accessCode';
import { RootState } from './store/store';
import { AccessCodeType } from './store/actions/accessCodeActions';
import Clients from './views/clients/clients';
import Advisors from './views/advisors/advisors';
import AdvisorDetails from './views/advisors/advisorDetails';
import ClientDetails2 from './views/clients/clientDetails2';
import UpdatePassword from './views/updatePassword';
import ProfileView from './views/profileView';
import AutoLogout from './components/AutoLogout';


const AccessCodeWrapper = () => {
  const { id } = useParams();  // Extract `id` from URL
  const [accessCode, setAccessCode] = useState<AccessCodeType | undefined>();
  const { accessCodes } = useSelector((state: RootState) => state.accessCode_slice);
  useEffect(() => {
      if(id){
          setAccessCode(accessCodes?.filter(item => item.id == id)[0])
      }
      
  }, [id]);

  // Ensure the access code is loaded before rendering
  if (!accessCode) return <div>Loading...</div>;

  return <AccessCode accessCode={accessCode} />;
};
interface ProtectedRouteProps {
  element: JSX.Element;
  isAuthenticated: boolean;
}

function ProtectedRoute({ element, isAuthenticated }: ProtectedRouteProps) {
  return isAuthenticated ? element : <Navigate to="/login" />;
}
interface AdminRouteProps {
  element: JSX.Element;
}

const AdminRoute = ({ element }: AdminRouteProps) => {
  const { isAuthenticated, user } = useSelector(selectState);

  // Check if the user is authenticated and if the business name is 'elisyan'
  const isAdmin = isAuthenticated && user?.business?.name === 'elisyan';

  return isAdmin ? element : <Navigate to="/" />;
};

const App = () => {
  const { isAuthenticated } = useSelector(selectState);
  

  return (
    <>
    <AutoLogout />
    <Router>
      <Routes>
        <Route path="/" element={<ProtectedRoute element={<Layout><Dashboard /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/changePassword" element={<ProtectedRoute element={<Layout><UpdatePassword /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/profile" element={<ProtectedRoute element={<Layout><ProfileView /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/contracts" element={<AdminRoute element={<Layout><AddContractForm /></Layout>} />} /> 
        <Route path="/addToWaitlist" element={<ProtectedRoute element={<Layout><AddToWaitlist /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/login" element={<Login />} />
        <Route path="/sendNotification" element={<ProtectedRoute element={<Layout><SendNotification /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/generateReferalCode" element={<AdminRoute element={<Layout><GenerateReferralCode /></Layout>} />} />
        <Route path="/accessCodes" element={<ProtectedRoute element={<Layout><AccessCodes /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/accessCode/:id" element={<ProtectedRoute element={<Layout><AccessCodeWrapper /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/clients" element={<ProtectedRoute element={<Layout><Clients /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/advisors" element={<ProtectedRoute element={<Layout><Advisors /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/clientDetails" element={<ProtectedRoute element={<Layout><ClientDetails2 /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/advisorDetails" element={<ProtectedRoute element={<Layout><AdvisorDetails /></Layout>} isAuthenticated={isAuthenticated} />} />
        <Route path="/generateLicence" element={<ProtectedRoute element={<Layout><GenerateLicences /></Layout>} isAuthenticated={isAuthenticated} />} />
      </Routes>
  </Router>
  {/* Add ToastContainer to render notifications */}
  <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
         // backgroundColor: '#333', // Default background color for all toasts
          //color: '#fff', // Default text color for all toasts
        }}
        progressStyle={{
          backgroundColor: '#6B7A8E', // Customize progress bar color (default blue)
        }}
      />

  </>
  );
}

export default App;
