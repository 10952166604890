import React from 'react';
import { ReactComponent as ArrowRight } from '../../src/assets/images/arrowRight.svg'
import useCustomStyles from '../hooks/useCustomStyles';
import { accessCodesStyles } from '../assets/styles/accessCodesStyles';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import { ClientType } from '../store/slices/clientsSlice';
import { NavLink, useNavigate } from 'react-router-dom';
interface DataRowProps {
  items: string[];
  index: number;
  link?: string;
  isHeader?: boolean;
  handleNavigate?: (client: ClientType) => void; // Accepts a client parameter
  client?: ClientType; // Add client prop to pass the client object
}

const CustomTableRow: React.FC<DataRowProps> = ({items, index, link, isHeader, handleNavigate, client }) => {
  const theme = useSelector((state: RootState) => state.theme_slice.theme);
  const classes = useCustomStyles(accessCodesStyles, theme);
  const navigate = useNavigate()
  return (
    <>
    {items.length > 0 &&
     <tr key={`row-item-${index}`} 
     className={classes.tr} 
     onClick={() => {
      if (handleNavigate && client) {
        handleNavigate(client);
      } else {
       if(link){
        navigate(link);
       } 
      }
    }}>
      {/*<td className={classes.td}>{index + 1}</td>*/}
      {items.map(item => 
         isHeader? <th key={`th-row-item-${index}-${item}`} className={ classes.th }>{item}</th>
        : <td key={`tr-row-item-${index}-${client?.id}-${item}`} className={ classes.td}>{item}</td>
      )}
      {handleNavigate && client&& <td
              key={`td-arrow-item-${index}-${client.id}`}
              className={classes.td}
              onClick={() => handleNavigate?.(client!)} // Call handleNavigate if defined
            >
          <ArrowRight />
        </td>
      }
    {link && <td key={`td-arrow-item-${index}`} className={classes.td}>
        <NavLink
           to={link}
           className={({ isActive }) =>
          `${classes.link} ${isActive ? classes.activeLink : ''}`
        }
      >
        <span className={classes.arrow}> <ArrowRight/></span>
      </NavLink>
      </td>
        } 
    </tr>
    }
   </> 
  );
};

export default CustomTableRow;