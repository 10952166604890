import React, { useState } from 'react';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/CustomDisplayInputStyles';
import { useSelector } from 'react-redux';
import { selectState } from '../store/slices/themeSlice';
import { ReactComponent as OpenEye } from "../assets/images/eye_open_gray.svg";
import { ReactComponent as CloseEye } from "../assets/images/eye_close_gray.svg";

// Define the props interface for the component
interface CustomDisplayInputProps {
  name: string;
  value: string | number | undefined | Date;
  placeholder?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlure?: (event: React.FocusEvent<HTMLInputElement>) => void; 
  type?: string; // Optional, default is "text"
  disabled?: boolean;
  label?: string;
  error?: string;
  min?: number;
  max?: number;
  icon?: React.ReactNode; // Add icon prop of type ReactNode
  handleIconClick?: () => void; // Add icon click handler
  autoComplete?: string;
}

const CustomDisplayInput: React.FC<CustomDisplayInputProps> = ({
  name,
  value,
  placeholder = '',
  onChange,
  onBlure,
  type = 'text',
  disabled = false,
  label,
  error,
  min,
  max,
  icon,
  handleIconClick,
  autoComplete
}) => {
  const { theme } = useSelector(selectState);
  const classes = useCustomStyles(customStyles, theme);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  // Convert Date to string for the input value
  const inputValue = value instanceof Date ? value.toISOString().slice(0, 10) : value;
  
  // Set the minimum date for date input types
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const dd = String(today.getDate()).padStart(2, '0');
  const setMinDate = () => `${yyyy}-${mm}-${dd}`;

  return (
    <div className={classes?.customInputContainer}> {/* Container wrapper */}
      {label && <label className={classes?.customInputLabel}>{label}</label>} {/* Render label if provided */}
      
      <div className={`${classes?.inputWrapper} ${disabled? classes.disabledInput : ''}`}> {/* Input and icon wrapper */}
        <input
          name={name}
          value={inputValue}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlure}
          type={type == 'password'? isPasswordVisible ? 'text' : 'password' : type}
          className={`${classes?.customInput} ${disabled ? classes.disabledInput : ''}`}
          disabled={disabled}
          min={min ? min : type === 'date' ? setMinDate() : undefined}
          max={max}
          autoComplete={autoComplete? autoComplete : 'on'}
        />
        
        {/* Render the icon with click handler if provided */}
        {icon && (
          <span onClick={handleIconClick} className={classes?.iconContainer}>
            {icon}
          </span>
        )}
        {type == 'password' && (
        <button
          onClick={()=> setIsPasswordVisible(state => !state)}
          style={{
            marginLeft: "auto",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          {isPasswordVisible ? (
            <div style={{ padding: 0 }}>
              <OpenEye height={20} width={20} />
            </div>
          ) : (
            <div style={{ padding: 0 }}>
              <CloseEye height={20} width={20} />
            </div>
          )}
        </button>
      )}
      </div>
      
      {/* Conditionally render error message */}
      {error && <p className={classes?.error}>{error}</p>}
    </div>
  );
};

export default CustomDisplayInput;
