import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const header2Styles = (theme: Theme): { [key: string]: Record<string, CSSObject | string | number> } => ({
    /* Navigation list (dropdown menu) */
    container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '1rem',
        backgroundColor: '#fff', // Replace with theme color or default
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        position: 'relative',
      },
    
      logo: {
        display: 'flex',
        alignItems: 'center',
      },
    
      hamburgerIcon: {
        cursor: 'pointer',
        display: 'flex',
        alignItems: 'center',
      },
    
      // Dropdown menu container
      dropdownMenu: {
        position: 'absolute',
        top: '100%',
        right: 0,
        backgroundColor: '#fff', // Replace with theme color or default
        borderRadius: '0px 0px 8px 8px',
        padding: '0.5rem',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        zIndex: 10,
        width: '200px',
      },
    
      // Dropdown list
      dropdownList: {
        listStyle: 'none',
        margin: 0,
        padding: 0,
      },
    
      // Dropdown list items
      dropdownItem: {
        padding: '0.5rem 1rem',
        cursor: 'pointer',
        fontWeight: 'bold',
        color:  theme.colorSchema.colors.mainText,
        transition: 'background 0.2s ease',
        '&:hover': {
          backgroundColor: '#f0f0f0', // Replace with theme color or default
        },
      },
    
      // Mobile responsiveness
      '@media (max-width: 768px)': {
        dropdownMenu: {
          width: '150px',
        },
      },
      overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
      },
      modal: {
        backgroundColor: '#fff',
        padding: '1.5rem',
        borderRadius: '8px',
        textAlign: 'center',
        maxWidth: '250px',
        width: '100%',
        color: theme.colorSchema.colors.mainText,
      },
      buttons: {
        display: 'flex',
        justifyContent: 'space-around',
        marginTop: '2rem',
        
      },
      confirmButton: {
        background: `linear-gradient(to right, ${theme.colorSchema.buttons.primary.background[0]}, ${theme.colorSchema.buttons.primary.background[1]})`,
        color: '#fff',
        border: 'none',
        fontWeight: 500,
        padding: '0.6rem 2rem',
        cursor: 'pointer',
        borderRadius: '25px',
      },
      cancelButton: {
        backgroundColor: '#ddd',
        color: '#333',
        border: 'none',
        cursor: 'pointer',
        borderRadius: '25px',
        fontWeight: 500,
        padding: '0.6rem 2rem',
      },
  });
  


