import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { login, logout, updateProfileAction } from '../actions/authActions';
import { UserData } from '../actions/authActions';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from '../../utils';

type State = {
  user: UserData | null;
  loading: boolean;
  error: string | Error | null | undefined;
  isAuthenticated: boolean;
  isSideNavOpen: boolean;
};

// Default initial state
const initialState: State = {
  user: null,
  loading: false,
  error: null,
  isAuthenticated: false,
  isSideNavOpen: true,
};

// Get the stored state or fall back to the default initialState
const persistedState = { ...initialState, ...loadStateFromLocalStorage<Partial<State>>('authState') };

const authSlice = createSlice({
  name: 'auth_slice',
  initialState: persistedState,
  reducers: {
    setError: (state: State, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
    },
    setIsSideNavOpen: (state: State) => {
      state.isSideNavOpen = !state.isSideNavOpen;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.isAuthenticated = true;
        state.user = action.payload;
        // Persist only user and isAuthenticated
        saveStateToLocalStorage('authState', { user: state.user, isAuthenticated: state.isAuthenticated });
      }
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loading = false;
      // Ensure `state.error` is always a string or null
      if (typeof action.payload === 'string') {
        state.error = action.payload;
      } else if (action.error?.message) {
        state.error = action.error.message; // Convert error object to string
      } else {
        state.error = 'Unknown error occurred';
      }
    });
    builder.addCase(logout.pending, (state) => {
      state.loading = true
    });
    builder.addCase(logout.fulfilled, (state) => {
      state.user = null;
      state.isAuthenticated = false;
      state.loading = false
      // Update localStorage after logout
      saveStateToLocalStorage('authState', { user: state.user, isAuthenticated: state.isAuthenticated });
      saveStateToLocalStorage('accessCodeState', { });
      saveStateToLocalStorage('clientsState', { });
    });
    builder.addCase(logout.rejected, (state, action) => {
      // Handle logout error if needed
      state.loading = false
      state.error = action.payload as string; // Assuming payload is a string error message
    });
    builder.addCase(updateProfileAction.pending, (state) => {
      state.loading = true
    });
    builder.addCase(updateProfileAction.fulfilled, (state, action) => {
      //state.user = null;
      //state.isAuthenticated = false;
      state.loading = false
      state.error = undefined
      if(state.user && action.payload &&  action.payload.id && action.payload.email ){
        state.user = {...state.user, 
          email: action.payload.email, 
          id: action.payload.id,
        }
      }
      // Update localStorage after logout
     // saveStateToLocalStorage('authState', { user: state.user, isAuthenticated: state.isAuthenticated });
     // saveStateToLocalStorage('accessCodeState', { });
      //saveStateToLocalStorage('clientsState', { });
    });
    builder.addCase(updateProfileAction.rejected, (state, action) => {
      // Handle logout error if needed
      state.loading = false
      state.error = action.payload as string; // Assuming payload is a string error message
    });
  },
});


export const selectState = (state: RootState) => state.auth_slice;
export const { setError, setIsSideNavOpen } = authSlice.actions;
export default authSlice.reducer;
