import React from "react";
import { useSelector } from "react-redux";
import { selectState } from "../../store/slices/themeSlice";

const ClientAdvisorButton: React.FC<{
  number: number;
  mainText: string;
  onPress?: () => void;
  width: number;
}> = ({ number, mainText, onPress, width }) => {
  const { theme } = useSelector(selectState);
  //   const colors = schemaColor.colorSchema.colors;
  // const width = window.innerWidth;
  return (
    <div
      onClick={onPress}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "20px",
        gap: "10px",
        width: width * 0.45,
        height: "90px",
        background: "#FFFFFF",
        borderRadius: "8px",
        // margin: "16px 16px",
        // marginLeft: "20px",
        // marginRight: "50px",
        // marginInline: "20px",
        // marginInlineEnd: "100px",
        // border: "1px solid #ccc",
        boxSizing: "border-box",
        boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "70px",
          height: "40%",
          padding: "0px",
          gap: "8px",
          // position: 'absolute',
          left: "16px",
          top: "16px",
          color: "#6B6B6B",
        }}
      >
        <p
          style={{
          //  fontFamily: "Gilroy-Bold",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "32px",
            lineHeight: "40px",
            margin: 0, // Reset margin for p element
          }}
        >
          {number}
        </p>
      </div>
      <div
        style={{
          alignItems: "flex-start",
          width: "70px",
          height: "90px",
          padding: "0px",
          gap: "8px",
          // position: 'absolute',
          left: "16px",
          top: "16px",
          color: "#6B6B6B",
        }}
      >
        <p
          style={{
           // fontFamily: "Gilroy-Bold",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            lineHeight: "40px",
            margin: 0, // Reset margin for p element
          }}
        >
          {mainText}
        </p>
      </div>
    </div>
  );
};

export default ClientAdvisorButton;
