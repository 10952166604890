import React, { useState } from 'react';
import { AppDispatch, RootState } from '../store/store';
import { useDispatch, useSelector } from 'react-redux';
import { sendNotificationByTopic } from '../store/actions/notificationActions';
import { selectState } from '../store/slices/notificationSlice';
import './sendNotifications.css';
import CustomButton from './CustomButton';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/notificationForm';
import CustomInput from './CustomInput';
import CustomSelect from './CustomSelectInput';
import { selectTranslationState } from '../store/slices/languageSlice';

const SendNotification = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, notifTypes} = useSelector(selectState);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('What is Lorem Ipsum?Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.');
  const [userId, setUserId] = useState('');
  const [topic, setTopic] = useState('active');
 // const [selectedToken, setSelectedToken] = useState(deviceTokens? deviceTokens[0] : '');
  const topics = ['all', 'active', 'subscribed', 'unsubscribed', ];
  const [selectedNotificationType, setSelectedNotificationType] = useState(notifTypes? notifTypes[0] : '')
  const {theme, activeTheme, mode} = useSelector((state: RootState) => state.theme_slice);
  const classes = useCustomStyles(customStyles, theme);
  const {t} = useSelector(selectTranslationState)
  console.log('activetheme, mode', activeTheme, mode)
  console.log('theme::::>>>>>>>>>>>',theme.colorSchema.colors.mainText)
  const handleTopicChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setTopic(e.target.value);
  };

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  }; 

  const handleUserIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(e.target.value);
  }; 
  
  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
  };
  /*
  const handleUserIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserId(e.target.value);
  };
 

  const handleTokenChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedToken(e.target.value);
  };

 

  const handleClick = () => {
    if (selectedToken) {
      dispatch(sendNotification({ message, title, token: selectedToken , type: selectedNotificationType})); // Include all necessary data
    } else {
      // Handle case where no token is selected
      console.error('Please select a device token');
    }
  };

  const getUserBackup = () => {
    dispatch(getUserCredentialBackup(userId)); // Include all necessary data
  }
 */
 const handleTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedNotificationType(e.target.value);
  };
  const sendNotifByTopic = () => {
    if (topic) {
      dispatch(sendNotificationByTopic({ message, title, topic, type: selectedNotificationType, userId })); // Include all necessary data
    } else {
      console.error('Please select a topic');
    }
  }
 

  return (
    <div className={classes?.container}>
     
    {/** <div className="notification-form">
      <input
          type="text"
          name="userId"
          placeholder="UserId"
          className="notification-input"
          value={userId}
          onChange={e => handleUserIdChange(e)}
        />
      <button onClick={getUserBackup}>Get user backup</button>
    </div> */}
   
    {/*
    <div className="notification-form">
      <h2 className='title'>Create Notification</h2>
      
      <select name="device-token-select" className="device-token-select" value={selectedToken} onChange={e => handleTokenChange(e)}>
      <option value="" disabled>Select a device token</option>
        {deviceTokens && deviceTokens.map((token, index) => (
          <option key={index} value={token}>
            {token}
          </option>
        ))}
      </select>
  
      <select name="notification-type-select" className="device-token-select" value={selectedNotificationType} onChange={e => handleTypeChange(e)}>
      <option value="" disabled>Select the type</option>
        {notifTypes && notifTypes.map((type, index) => (
          <option key={index} value={type}>
            {type}
          </option>
        ))}
      </select>
      <input
        type="text"
        name="title"
        placeholder="Title"
        className="notification-input"
        value={title}
        onChange={e => handleTitleChange(e)}
      />
      <textarea
        name="message"
        value={message}
        placeholder="Put message here..."
        className="notification-textarea"
        onChange={e => handleMessageChange(e)}
      />
      <button className={loading? 'loadingButton' : 'button'} onClick={handleClick} disabled={title.length == 0 || message.length == 0}>{loading ?  <img className='loadingIcon' src={LoadingCercle} alt="loadingGif" /> : 'Send Message'}</button>
      <h5 className='error'>{error}</h5>
    </div>
   */}
    <div className={classes?.notificationForm}>
     <h1 className={classes?.title}>{t[activeTheme].notificationScreen.form.title}</h1>
     <hr className={classes?.divider}></hr>
     <CustomSelect name={'topic'} value={selectedNotificationType} options={notifTypes} onChange={e => handleTypeChange(e)} />
     <CustomSelect name={'topic'} value={topic} options={topics} onChange={e => handleTopicChange(e)} />
     <CustomInput name='title' value={title} placeholder={t[activeTheme].notificationScreen.form.titleInput.placeholder} onChange={e =>handleTitleChange(e)}     
     />
     <CustomInput name='userId' value={userId} placeholder={t[activeTheme].notificationScreen.form.userIdInput.placeholder} onChange={e =>handleUserIdChange(e)}     
     />
     <textarea
       name="message"
       value={message}
       placeholder={t[activeTheme].notificationScreen.form.textArea.placeholder}
       className={classes?.notificationTextarea}
       onChange={e => handleMessageChange(e)}
     />
     <CustomButton
      title={t[activeTheme].notificationScreen.form.button.title}
      name='sendNotificationByTopic'
      handleClick={sendNotifByTopic}
      disabled={title.length == 0 || message.length == 0}
      loading = {loading}
    />
   </div>

  </div>
  )
}

export default SendNotification