import React, { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../store/actions/authActions';
import { AppDispatch } from '../store/store';

const worker = new Worker(`${process.env.PUBLIC_URL}/logoutWorker.js`);

const AutoLogout: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const INACTIVITY_LIMIT = 15 * 60 * 1000; // e.g., 15 minutes

  const resetInactivityTimer = useCallback(() => {
    const currentTime = Date.now();
    localStorage.setItem('lastActivityTime', currentTime.toString());

    // Send the updated time to the worker
    if (worker) {
      worker.postMessage({ type: 'SET_LAST_ACTIVITY', lastActivityTime: currentTime });
    }
  }, []);

  useEffect(() => {
    // Initialize Web Worker
    //const worker = new Worker(`${process.env.PUBLIC_URL}/logoutWorker.js`);

    // Send inactivity limit to worker
    worker.postMessage({ type: 'SET_INACTIVITY_LIMIT', limit: INACTIVITY_LIMIT });

    // Listen for messages from the worker
    worker.onmessage = (event) => {
      if (event.data.type === 'LOGOUT') {
        dispatch(logout());
        localStorage.setItem('isLoggedOut', 'true');
      }
    };

    // Set initial last activity time
    const initialTime = Date.now();
    localStorage.setItem('lastActivityTime', initialTime.toString());
    worker.postMessage({ type: 'SET_LAST_ACTIVITY', lastActivityTime: initialTime });

    // Add event listeners to reset inactivity timer on user activity
    window.addEventListener('mousemove', resetInactivityTimer);
    window.addEventListener('keydown', resetInactivityTimer);

    return () => {
      // Cleanup event listeners and terminate the worker on component unmount
      window.removeEventListener('mousemove', resetInactivityTimer);
      window.removeEventListener('keydown', resetInactivityTimer);
      worker.terminate();
    };
  }, [dispatch, resetInactivityTimer]);

  useEffect(() => {
    // Check logout status on component mount
    const isLoggedOut = localStorage.getItem('isLoggedOut');
    if (isLoggedOut === 'true') {
      dispatch(logout());
      localStorage.removeItem('isLoggedOut'); // Clear flag after handling
    }
  }, [dispatch]);

  return null;
};

export default AutoLogout;
