import React from "react";
import { useSelector } from "react-redux";
// import CachedImage from '../../../components/CachedImage';
import ProgressBarApp from "./ProgessBar";
// import { selectConfigTheme } from '../../../store/slices/utilsSlice';

const UnassignedLicensesButton: React.FC<{
  unassignedLicenseNumber: number;
  unassignedLicenseLimitNumber: number;
  mainText: string;
  onPress?: () => void;
  width: number;
}> = ({
  unassignedLicenseNumber,
  unassignedLicenseLimitNumber,
  mainText,
  onPress,
  width,
}) => {
  //   const schemaColor = useSelector(selectConfigTheme);
  //   const colors = schemaColor.colorSchema.colors;
  // const width = window.innerWidth; // Web equivalent for width and height

  return (
    <button
      onClick={onPress}
      style={{
        display: "flex",
        flexDirection: "column",
        padding: "16px 8px",
        width: width,
        backgroundColor: "#FFFFFF",
        borderColor: "#FFFFFF",
        borderRadius: "8px",
        border: "none",
        minHeight: "40px",
        marginTop: "30px",
        // margin: "16px 16px",
        boxSizing: "border-box",
        boxShadow: "3px 3px 6px rgba(0, 0, 0, 0.2)",
      }}
    >
      <div style={{ marginRight: "20px" }}>
        <span
          style={{
           // fontFamily: "Gilroy-Bold",
            fontWeight: "400",
            fontSize: "32px",
            color: "#595959",
            // marginRight: 'auto',
            marginLeft: "16px",
          }}
        >
          {unassignedLicenseNumber}
        </span>
      </div>

      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <p
          style={{
            fontSize: "16px",
          //  fontFamily: "Gilroy-Regular",
            fontWeight: "400",
            color: "#595959",
            marginLeft: "16px",
            // marginVertical: '8px',
            // marginInline: '16px',
            marginTop: "8px",
            marginBottom: "8px",
            // minWidth: `${width * 0.2}px`,
            marginRight: "auto",
            textAlign: "right",
          }}
        >
          {mainText}
        </p>
        {/* style={{fontFamily: 'Gilroy-Bold',
                fontStyle: 'normal',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '40px',
                margin: 0, // Reset margin for p element
                }}
        */}
      </div>
      <div
        style={{
          alignItems: "center",
          alignContent: "center",
          alignSelf: "center",
        }}
      >
        <ProgressBarApp
          limit={unassignedLicenseLimitNumber}
          currentCount={unassignedLicenseNumber}
          width={width - 10}
        />
      </div>
    </button>
  );
};

export default UnassignedLicensesButton;
