import React from 'react';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/customInputStyles';
import { useSelector } from 'react-redux';
import { selectState } from '../store/slices/themeSlice';

// Define the props interface for the component
interface CustomInputProps {
  name: string;
  value: string | number | undefined | Date;
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlure?: (event: React.FocusEvent<HTMLInputElement>) => void; 
  type?: string; // Optional, default is "text"
  disabled?: boolean;
  label?: string;
  error?: string;
  min?: number;
  max?: number|string;
}

const CustomInput: React.FC<CustomInputProps> = ({
  name,
  value,
  placeholder = '',
  onChange,
  onBlure,
  type = 'text',
  disabled = false,
  label,
  error,
  min,
  max
}) => {
  const { theme } = useSelector(selectState);
  const classes = useCustomStyles(customStyles, theme);
  // Convert Date to string for the input value
  const inputValue = value instanceof Date ? value.toISOString().slice(0, 10) : value;
  const today = new Date();
  const yyyy = today.getFullYear();
  const mm = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const dd = String(today.getDate()).padStart(2, '0');
  const setMinDate = () => {return `${yyyy}-${mm}-${dd}`}; // Set the min date
  return (
    <div className={classes?.customInputContainer}> {/* Add container wrapper */}
      {label && <label className={classes?.customInputLabel}>{label}</label>} {/* Render label if provided */}
    <input
      name={name}
      value={inputValue}
      placeholder={placeholder}
      onChange={onChange}
      onBlur={onBlure}
      type={type}
      className={classes?.customInput}
      disabled={disabled}
      min={min? min : type == 'date'? setMinDate() : undefined}
      max= {max}
    />
     {/* Conditionally render error message */}
     {error && <p className={classes?.error}>{error}</p>}
    </div>
  );
};

export default CustomInput;
