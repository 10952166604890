import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import CustomButton from '../components/CustomButton';
//import Header from '../components/Header';
//import useCustomStyles from '../hooks/useCustomStyles';
//import { customStyles } from '../assets/styles/notificationForm';
//import { selectTranslationState } from '../store/slices/languageSlice';
//import { selectState } from '../store/slices/themeSlice';
import CustomInput from '../components/CustomInput';
import { AppDispatch } from '../store/store';
import { addToWaitlist } from '../store/actions/notificationActions';

const AddToWaitlist = () => {
  //const dispatch = useDispatch();
 // const { t } = useSelector(selectTranslationState);
  //const { theme } = useSelector(selectState)
 // const classes = useCustomStyles(customStyles, theme);
  const dispatch = useDispatch<AppDispatch>();
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [sentence, setSentence] = useState('');


  const handleAddToWaitlist = async() => {
    // Dispatch your code generation action here if needed
    await dispatch(addToWaitlist( {name, email, sentence} ));
  };

  return (
    <>       
        <CustomInput
            name='email'
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Add email"
        />
       
        <CustomInput
            name='name'
            type="text" 
            value={name} 
            onChange={(e) => setName(e.target.value)} 
            placeholder="Put your name here"
        />

        <CustomInput
            name='sentence'
            type="text" 
            value={sentence} 
            onChange={(e) => setSentence(e.target.value)} 
            placeholder="How did you hear about us!"
        />
    
        <CustomButton
            title="Add to waitlist"
            name="addToWaitlist"
            handleClick={handleAddToWaitlist} loading={false} 
        />
    </>
  );
};

export default AddToWaitlist;
