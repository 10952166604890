import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const profileStyles = (theme: Theme): { [key: string]: Record<string, CSSObject | string | number> } => ({ 
    profileSection: {
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
     // marginBottom: '2vh',
    },
    profileImage: {
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      objectFit: 'cover',
      
      
    },
    pictureContainer: {
     // marginBottom: '10px',
      marginRight: '10px',
      width: '65px',
      height: '65px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      //border: `2px solid ${theme.colorSchema.colors.thirdText}`,
      boxShadow: `0px 2px 5px ${theme.colorSchema.colors.mainText}`,
    },
    profileDetails: {
      //textAlign: 'center',
      color: theme.colorSchema.colors.mainText,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center'
    },
    userName: {
      fontWeight: 'bold',
      fontSize: '1rem',
      margin: 0
    },
    userRole: {
      fontStyle: 'italic',
      color: theme.colorSchema.colors.secondaryText,
      margin: 0
    },

    container: {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      width: "100%",
      margin: "auto",
    },

    profileHeader: {
      width: '100%',
      height: '20vh',
      display: 'flex',
      '& svg': {
        width: '100%',
        height: '100%',
        display: 'block',  // Prevents any extra whitespace
        objectFit: 'cover',
      },
    },

    profileBody: {
      position: 'relative',
      width: '100%',
      minHeight: '90vh'
    },

    profileIcon: {
      position: 'absolute',
      top: '-37px',
      left: '5%',
      width: '75px',
      height: '75px',
      backgroundColor: theme.colorSchema.colors.secondaryBackground,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%'
    }

  });
  