import { createSlice } from '@reduxjs/toolkit'; //importing create slice
import { RootState } from '../store';
import { addToWaitlist } from '../actions/notificationActions';
import { AccessCodeType, addAccessCode, addContract, addReferralCode, BusinessesType, ContractType, getAccessCodes, getBusinesses, getContracts } from '../actions/accessCodeActions';
import { loadStateFromLocalStorage, saveStateToLocalStorage } from '../../utils';



type Waitlist = {
  email: string;
  name: string;
  sentence: string | undefined;
  referral_code?: string | undefined;
  id: string;
  created_at?: Date | string | undefined;
  updated_at?: Date | string | undefined;
}

type State = {
  loading: boolean;
  error: string | undefined;
  waitlist : Waitlist[] | [];
  businesses: BusinessesType[] | undefined;
  contracts: ContractType[] | undefined;
  accessCodes: AccessCodeType[] | undefined;
};

const initialState: State = {
  waitlist: [],
  loading: false,
  error: undefined,
  businesses: undefined,
  contracts: undefined,
  accessCodes: undefined,
};

const persistedState = { ...initialState, ...loadStateFromLocalStorage<Partial<State>>('accessCodeState') };

const accessCodeSlice = createSlice({
  name: 'accessCode_slice',
  initialState: persistedState,
  reducers: {
    logout: () => {
      // Reset the other state on logout
      return initialState;
    },
    setContract: (state, action) => {
      state.contracts = state.contracts?.map(contract => {
        if(contract.id == action.payload.id){
          return {...action.payload}
        }else{
          return contract
        }
      })
    },
    setContracts: (state, action) => {
      const updatedContracts = action.payload;  // This should be an array of ContractType
    
      // Map through the current contracts and update them based on the payload
      state.contracts = state.contracts?.map(contract => {
        // Find the updated contract in the payload by matching the ID
        const updatedContract = updatedContracts.find((c: ContractType )=> c.id === contract.id);
    
        // If an updated contract is found, return the updated contract; otherwise, return the existing contract
        return updatedContract ? { ...updatedContract } : contract;
      }) || updatedContracts;  // If state.contracts is undefined, set it to the payload
    },
    setLicence: (state, action) => {
      state.accessCodes = state.accessCodes?.map(code => {
        if(code.id == action.payload.id){
          return {...action.payload}
        }else{
          return code
        }
      })
    },
  },
  extraReducers: (builder) => { 
    builder.addCase(addToWaitlist.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(addToWaitlist.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload) {
        state.waitlist = [...state.waitlist, action.payload];
        console.log('addTowaitlist fullfiled extraReducer ::', action.payload);
      }
    });
    builder.addCase(addToWaitlist.rejected, (state, action) => {
      state.loading = false;
      if (typeof action.payload === 'string'){
        state.error = action.payload;
      } else if(action.payload instanceof Error){
        state.error = action.payload.message;
      }else{
        state.error = 'Unknown error occurred while adding new client to waitlist!';
      }
    });
    builder.addCase(getBusinesses.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getBusinesses.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.businesses = action.payload
          saveStateToLocalStorage('accessCodeState', { businesses: state.businesses, contracts: state.contracts, accessCodes: state.accessCodes });
        }
      });
      builder.addCase(getBusinesses.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'string'){
          state.error = action.payload;
        } else if(action.payload instanceof Error){
          state.error = action.payload.message;
        }else{
          state.error = 'Unknown error occurred while fetching businesses';
        }
      });
      builder.addCase(getContracts.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getContracts.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.contracts = action.payload
          saveStateToLocalStorage('accessCodeState', { businesses: state.businesses, contracts: state.contracts, accessCodes: state.accessCodes });
        }
      });
      builder.addCase(getContracts.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'string'){
          state.error = action.payload;
        } else if(action.payload instanceof Error){
          state.error = action.payload.message;
        }else{
          state.error = 'Unknown error occurred while fetching contracts';
        }
      });

      builder.addCase(getAccessCodes.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(getAccessCodes.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
          state.accessCodes = action.payload
          saveStateToLocalStorage('accessCodeState', { businesses: state.businesses, contracts: state.contracts , accessCodes: state.accessCodes});
        }
      });
      builder.addCase(getAccessCodes.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'string'){
          state.error = action.payload;
        } else if(action.payload instanceof Error){
          state.error = action.payload.message;
        }else{
          state.error = 'Unknown error occurred while fetching contracts';
        }
      });

      builder.addCase(addContract.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(addContract.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
         state.contracts = state.contracts? [...state.contracts, action.payload] : [action.payload]
         saveStateToLocalStorage('accessCodeState', { businesses: state.businesses, contracts: state.contracts, accessCodes: state.accessCodes});
        }
      });
      builder.addCase(addContract.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'string'){
          state.error = action.payload;
        } else if(action.payload instanceof Error){
          state.error = action.payload.message;
        }else{
          state.error = 'Unknown error occurred while adding new contracts';
        }
      });
      builder.addCase(addAccessCode.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(addAccessCode.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
         state.accessCodes = state.accessCodes? [...state.accessCodes, ...action.payload] : [...action.payload]
         saveStateToLocalStorage('accessCodeState', { businesses: state.businesses, contracts: state.contracts , accessCodes: state.accessCodes});
        }
      });
      builder.addCase(addAccessCode.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'string'){
          state.error = action.payload;
        } else if(action.payload instanceof Error){
          state.error = action.payload.message;
        }else{
          state.error = 'Unknown error occurred while adding new contracts';
        }
      });
      builder.addCase(addReferralCode.pending, (state) => {
        state.loading = true;
      });
      builder.addCase(addReferralCode.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload) {
         state.accessCodes = state.accessCodes? [...state.accessCodes, ...action.payload] : [...action.payload]
         saveStateToLocalStorage('accessCodeState', { businesses: state.businesses, contracts: state.contracts , accessCodes: state.accessCodes});
        }
      });
      builder.addCase(addReferralCode.rejected, (state, action) => {
        state.loading = false;
        if (typeof action.payload === 'string'){
          state.error = action.payload;
        } else if(action.payload instanceof Error){
          state.error = action.payload.message;
        }else{
          state.error = 'Unknown error occurred while adding new contracts';
        }
      });
  },
});


export const selectAccessCodeState = (state: RootState) => state.accessCode_slice;
export const {logout, setContract, setLicence, setContracts} = accessCodeSlice.actions;

export default accessCodeSlice.reducer;