import React from "react";
import { useSelector } from "react-redux";
import { selectState } from "../../store/slices/themeSlice";

interface CustomProgressBarProps {
  limit: number; // Total limit, e.g., 10 customers
  currentCount: number; // Current value, e.g., 5 customers
  // width?: DimensionValue;
  variant?: "primary" | "golden";
  width: number;
}

const CustomProgressBar: React.FC<CustomProgressBarProps> = ({
  limit,
  currentCount,
  variant = "primary",
  width,
}) => {
  const calculateFillPercentage = () => {
    if (limit === 0) return 0; // Avoid division by zero
    return (currentCount / limit) * 100;
  };

  // const totalWidth = window.innerWidth * 0.21; // Width of the entire progress bar
  const totalWidth = width;
  const filledWidth = (calculateFillPercentage() / 100) * totalWidth;

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: totalWidth,
        height: "8px",
        backgroundColor: "#FFFFFF",
        borderRadius: "20px",
        overflow: "hidden",
        margin: "10px 0",
        borderTop: "1px solid #B9B9B9",
        borderLeft: "1px solid #B9B9B9",
        borderRight: "1px solid #B9B9B9",
      }}
    >
      {/* Filled part of the progress bar */}
      <div
        style={{
          height: "100%",
          width: `${filledWidth}px`,
          background:
            variant === "primary"
              ? `linear-gradient(to right, #6B7A8E,#6B7A8E)`
              : "linear-gradient(to right, #FFE998, #57370D)",
          borderRadius: "20px 0 0 20px", // Rounded on the left side only
        }}
      />
      {/* Unfilled part of the progress bar */}
      <div
        style={{
          height: "100%",
          width: `${totalWidth - filledWidth}px`,
          backgroundColor: "#FFFFFF",
        }}
      />
    </div>
  );
};

export default CustomProgressBar;
