// Function to get specific part of the state from localStorage
export const loadStateFromLocalStorage = <T>(key: string): T | undefined => {
    try {
      const serializedState = localStorage.getItem(key);
      if (serializedState === null) {
        return undefined; // Return undefined to use the default initial state
      }
      return JSON.parse(serializedState) as T; // Parse and return the stored state
    } catch (err) {
      console.error(`Error loading state from localStorage for key ${key}`, err);
      return undefined;
    }
  };
  
  // Function to save specific part of the state to localStorage
export const saveStateToLocalStorage = <T>(key: string, state: T) => {
    try {
      const serializedState = JSON.stringify(state);
      localStorage.setItem(key, serializedState);
    } catch (err) {
      console.error(`Error saving state to localStorage for key ${key}`, err);
    }
  };

export const capitalizeFirstLetter = (val: string) => {
    return String(val).charAt(0).toUpperCase() + String(val).slice(1);
}  
  
export const addSevenDays = (dateString: string) => {
  // Parse the input date string
  const [year, month, day] = dateString.split('-').map(Number);
  
  // Create a new Date object
  const date = new Date(year, month - 1, day);
  
  // Add 7 days
  date.setDate(date.getDate() + 7);
  
  // Format the new date as `${yyyy}-${mm}-${dd}`
  const newYear = date.getFullYear();
  const newMonth = String(date.getMonth() + 1).padStart(2, '0');
  const newDay = String(date.getDate()).padStart(2, '0');
  
  return `${newYear}-${newMonth}-${newDay}`;
}

export const getCurrentDateFormatted=() =>{
  const date = new Date();
  
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  
  return `${year}-${month}-${day}`;
}

  // You can now use `loadStateFromLocalStorage` and `saveStateToLocalStorage` in any reducer or slice.
  