
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import useCustomStyles from '../../hooks/useCustomStyles';
//import { customStyles } from '../../assets/styles/notificationForm';
import { selectTranslationState } from '../../store/slices/languageSlice';
import { selectState } from '../../store/slices/themeSlice';
import { AppDispatch, RootState } from '../../store/store';
import { AccessCodeType, ContractType, getContracts } from '../../store/actions/accessCodeActions';
import validationSchema from './scripts';
import { useNavigate, useParams } from 'react-router-dom';
import { accessCodeFormStyles } from '../../assets/styles/accessCodeFormStyles';
import CustomDisplay from '../../components/CustomDisplay';
import QRCode from 'react-qr-code';
import { ReactComponent as Download } from '../../assets/images/Download.svg';
import { customStyles } from '../../assets/styles/customStyles';
import CustomDisplayInput from '../../components/CustomDisplayInput';
import { capitalizeFirstLetter } from '../../utils';

import { ReactComponent as LightToggleSopher } from '../../assets/images/light_toggle_sopher.svg';
import { ReactComponent as DarkToggleSopher } from '../../assets/images/dark_toggle_sopher.svg';
import CustomButton from '../../components/CustomButton';
import { ReactComponent as ArrowRight } from '../../assets/images/arrowRight.svg';


const EditLicence = ({ accessCode }: { accessCode: AccessCodeType }) => {
    const { id } = useParams();  // Extract `id` from URL
    const { contracts, loading } = useSelector((state: RootState) => state.accessCode_slice);
    const { t } = useSelector(selectTranslationState);
    const { theme, activeTheme, mode } = useSelector(selectState);
    const { user } = useSelector((state: RootState) => state.auth_slice);
    
    const classes = useCustomStyles(accessCodeFormStyles, theme);
    const classes1 = useCustomStyles(customStyles, theme);
    const [contract, setContract ] = useState<ContractType | undefined>()
    const [editMode, setEditMode] = useState(false); // To track edit state
    const dispatch = useDispatch<AppDispatch>();
    const [availableLicences, setAvailableLicences] = useState(0)
    const navigate = useNavigate()

    const roleList = [
        { label: 'Marketing', value: 'Marketing' },
        { label: 'CRM', value: 'CRM' },
        { label: 'Administrator', value: 'Administrator' }
    ];
    
    const handleGetContracts = ()=>{
        dispatch(getContracts({business_client: (user?.business?.name == 'elisyan' || !user || !user.business) ? '' : user?.business?.id }))
    } 
    useEffect(() => { 
      if(accessCode.codeType == 'licence-code'){
         handleGetContracts();
      }  
    }, [])

    useEffect(()=>{
        if(accessCode){
          setContract(contracts?.find(item => item.id == accessCode?.contract_ref))
        }
    },[contracts])

    useEffect(()=>{
       if(contract?.useLimit && contract.licence_count){
        setAvailableLicences(contract?.useLimit - contract?.licence_count)
       }
    },[contract])

    const formik = useFormik({
        initialValues: {
        id: id,  
        roles: accessCode?.roles?.map(item => {return{label: item, value: item}}) || [],
        role_type: accessCode?.role_type,
        expire_at: contract?.expire_at,
        contract_ref: contract?.contract_ref,
        },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      console.log('Submitting edited form values:', values);
      setEditMode(false); // Reset edit mode after submission
    },
  });

  const { values, setFieldValue, handleSubmit } = formik;

  // Enable edit mode when user changes role_type or roles
  const handleFieldChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    console.log('value roles',values.roles, accessCode.roles)
    setFieldValue(name, value);
    if(name == 'roleType' && accessCode?.role_type == value){
        setEditMode(false);
    }else if(value == 'advisor' && accessCode?.roles?.toString() == values.roles.map(item => item.label).toString()){
        setEditMode(false);
    }else{
        setEditMode(true); // Enable the edit button on field change
    }
    
  };
  const qrWrapperRef = useRef<HTMLDivElement | null>(null);

  const downloadQRCode = () => {
    if (qrWrapperRef.current) {
      // Find the SVG element inside the wrapper
      const svg = qrWrapperRef.current.querySelector('svg');
      if (svg) {
        const svgData = new XMLSerializer().serializeToString(svg);
        const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);

        // Create a download link
        const link = document.createElement('a');
        link.href = url;
        link.download = `QR_Code_${id}.svg`;
        document.body.appendChild(link);
        link.click();

        // Cleanup
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      }
    }
  };



  return (
    <div className={classes1.container}>
      <div className={classes1.tableContainer}>
        <h2 className={classes1.header}>
         {accessCode.codeType == 'licence-code' ? 'LICENCE DETAILS' : 'REFERRAL CODE DETAILS'}
        </h2>
      </div> 
    <div style={{display: 'flex'}}>
      {/*<div className={classes?.container}>*/}
      <div className={classes1?.flexColumn}>
        <form onSubmit={handleSubmit} className={classes?.notificationForm}>
          {accessCode.codeType == 'licence-code' ? 
          <>
            <CustomDisplay label='Contract Reference' value={contract?.contract_ref} disabled={true}/>
            <CustomDisplay label='Available Licences' value={availableLicences} disabled={true}/>
            <CustomDisplay label={`Subscription Expiry`} value={contract?.expire_at} disabled={true}/>
            <CustomDisplay label={`Licence Type`} value={capitalizeFirstLetter(accessCode?.role_type)} disabled={true}/>
            {accessCode.role_type == 'advisor' && accessCode.roles &&
              accessCode.roles.map((role,index)=>(
                <CustomDisplay 
                key = {`role-${index}`} 
                disabled={true}
                label={index == 0? 'Roles' : ''}
                value={`${capitalizeFirstLetter(role)}`}
                //icon = {<LightToggleSopher width={25} height={25}/>}
                handleIconClick={()=>console.log('icon clicked!!!')}
              />
              ))
            }
          </>
          : 
          <>
            <CustomDisplay label={`End Business`} value={accessCode?.for_business_client} disabled={true}/>
            <CustomDisplay label={`Use Limit`} value={accessCode?.useLimit} disabled={true}/>
            <CustomDisplay label={`Use Count`} value={accessCode?.useCount} disabled={true}/>
            <CustomDisplay label={`Expiry Date`} value={accessCode?.expire_at} disabled={true}/>
          </>
          }
        {/*
          <CustomSelect
            label="Role Type"
            name="role_type"
            value={values.role_type}
            options={[
              { name: 'Client', id: 'client' },
              { name: 'Advisor', id: 'advisor' }
            ]}
            onChange={handleFieldChange}
            disabled={false} // Role type is editable
          />

          {values.role_type === 'advisor' && (
            <CustomMultiSelect
              name="roles"
              value={values.roles}
              onChange={(value) => {
                setFieldValue('roles', value)
               
                if(accessCode?.roles?.toString() == value.map(item => item.label).toString()){
                    setEditMode(false);
                }else{
                    setEditMode(true); // Enable the edit button on field change
                }
            }}
              options={roleList}
              label="Roles To Assign"
              disabled={false} // Roles are editable
            />
          )}

         

    
          {editMode && (
            <CustomButton
              title="Edit Licence"
              name="editLicence"
              handleClick={() => handleSubmit()}
              type="submit"
              loading={loading}
              disabled={loading}
            />
          )}*/}
        </form>
      </div>
      <div className={classes.accessCodeBox}>
      <CustomDisplay label={'Access Code'} value={accessCode?.id} allowCopy />
       {id && 
       <div className={classes.QRBox}>
       
        <div className={classes.QRWrapper} ref={qrWrapperRef}>    
          <QRCode value={id} size={180} />
        </div>
        <div 
          className={`${classes1.flexCenter} ${classes1.linkUnderline}`}
          onClick={downloadQRCode}
          style={{ cursor: 'pointer' }}
          >
          <Download width={20} height={20} /> Download QR Code</div>

    </div>}
      </div>
     {/* </div>*/}
    </div>
    </div>
  );
};

export default EditLicence;
