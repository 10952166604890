import {configureStore} from '@reduxjs/toolkit';
import notificationSlice from './slices/notificationSlice';
import authSlice from './slices/authSlice';
import translationSlice from './slices/languageSlice';
import themeSlice from './slices/themeSlice';
import accessCodeSlice from './slices/accessCodeSlice';
import clientsSlice from './slices/clientsSlice';

export const store = configureStore({
  reducer: {
    notification_slice: notificationSlice,
    auth_slice: authSlice,
    accessCode_slice: accessCodeSlice,
    translation_slice: translationSlice,
    theme_slice: themeSlice,
    clients_slice: clientsSlice,
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({serializableCheck: false}),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
