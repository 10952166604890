import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { RootState } from '../store/store';

import '../assets/styles/index.css';
import useCustomStyles from '../hooks/useCustomStyles';
import { sideNavigationStyles } from '../assets/styles/sideNavigation';
import { selectTranslationState } from '../store/slices/languageSlice';
import ProfileSection from './Profile';

function SideNavigation() {
  const { theme, activeTheme } = useSelector((state: RootState) => state.theme_slice);
  const classes = useCustomStyles(sideNavigationStyles, theme);
  const { t } = useSelector(selectTranslationState);
  // Get the user object from the state
  const { user } = useSelector((state: RootState) => state.auth_slice); // Adjust this according to your state structure

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    console.log('toggleMenus', isMenuOpen);
    setIsMenuOpen(!isMenuOpen);
  };

  // Check if the user is an admin of the 'elisyan' business
  const isElisyanAdmin = user?.business?.name === 'elisyan';

  return (
    <div>
      <ul onClick={() => toggleMenu()} className={`${classes?.navigationList} ${isMenuOpen ? classes?.navigationListOpen : ''}`}>
        <li className={classes.listItem}>
          <ProfileSection />
        </li>
        <li className={classes.listItem}>
          <NavLink to="/" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }>
            {t[activeTheme].header.navigationList.link5}
          </NavLink>
        </li>
        <li className={classes.listItem}>
          <NavLink to="/clients" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }>
            {t[activeTheme].header.navigationList.link_clients}
          </NavLink>
        </li>

        <li className={classes.listItem}>
          <NavLink to="/advisors" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }>
            {t[activeTheme].header.navigationList.link_advisors}
          </NavLink>
        </li>

        <li className={classes.listItem}>
          <NavLink to="/accessCodes" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }>
            {t[activeTheme].header.navigationList.link_access_codes}
          </NavLink>
        </li>
        {isElisyanAdmin && (
          <>
            <li className={classes.listItem}>
              <NavLink to="/contracts" className={({ isActive }) =>
                `${classes?.link} ${isActive ? classes?.activeLink : ''}`
              }>
                {t[activeTheme].header.navigationList.link6}
              </NavLink>
            </li>

            
            
         {/* <li className={classes.listItem}>
          <NavLink to="/sendNotification" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }>
            {t[activeTheme].header.navigationList.link4}
          </NavLink>
        </li>  */}
        
        <li className={classes.listItem}>
          <NavLink to="/generateReferalCode" className={({ isActive }) =>
            `${classes?.link} ${isActive ? classes?.activeLink : ''}`
          }>
            {t[activeTheme].header.navigationList.link3}
          </NavLink>
        </li>
          </>
        )}

      </ul>
    </div>
  );
}

export default SideNavigation;
