import { Theme } from "../../types";
import { CSSObject } from "@emotion/react";

export const customStyles = (
  theme: Theme
): { [key: string]: Record<string, CSSObject | string | number> } => ({
  customInput: {
    boxSizing: "border-box",
    width: "100%",
    padding: "10px",
    borderRadius: "8px",
    fontSize: "1rem",
    fontWeight: 550,
    border: "none",
    outline: 'none',
   // border: `2px solid ${theme.colorSchema.inputs.primary.outline}`,
    backgroundColor: theme.colorSchema.inputs.primary.background[0],
    // boxShadow: `0px 0px 10px 0px ${theme.colorSchema.colors.mainText}`,
    // color: theme.colorSchema.inputs.primary.text,
    transition: "border-color 0.3s ease",
    "&:focus": {
     // borderColor: theme.colorSchema.inputs.primary.background[1],
      outline: "none",
    },
    "&:disabled": {
      opacity: 0.5,
      cursor: "not-allowed",
    },
    // Custom Placeholder Styling
    "&::placeholder": {
     // color: theme.colorSchema.inputs.primary.text,
      opacity: 1,
    },
  },
  customInputContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "1.2rem",
  },
  customInputLabel: {
    marginBottom: "0.2rem",
    fontSize: "16px",
    color: theme.colorSchema.colors.mainText,
    //fontWeight: 'bold',
  },
  error: {
    color: "red",
    margin: 0,
  },
  customInputDisplay: {
    boxSizing: "border-box",
    width: "100%",
    padding: "10px",
    borderRadius: "8px",
    fontSize: "1rem",
    border: "none",
    outline: 'none',
    fontWeight: 550,
    //border: `2px solid ${theme.colorSchema.inputs.primary.outline}`,
    backgroundColor: theme.colorSchema.inputs.primary.background[0],
   // boxShadow: `0px 0px 10px 0px ${theme.colorSchema.colors.mainText}`,
   // color: theme.colorSchema.inputs.primary.text,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  flexComponent: {
    display: "flex",
    alignItems: "center",
  },
});
