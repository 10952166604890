import { createAsyncThunk } from "@reduxjs/toolkit";
import { ClientType } from "../slices/clientsSlice";
import axios from "axios";


export const getClientsAction = createAsyncThunk<
  ClientType[] | undefined,
  {business_name:string | undefined}
>(
  'clients_slice/getClientsAction',
  async ({ business_name }, thunkAPI) => {
    try {
     // console.log('inputs', business_name);
      //http://127.0.0.1:5001/elisyanwealth/us-central1/getClients
        const response = await axios.post('https://getclients-c5yieuvjca-uc.a.run.app', {business_name}, {
        headers: {
        'Content-Type': 'application/json',
        }});
      //  console.log('get clients list' , response)
        return response.data.data;
    } catch (e) {
      console.log('-------------- Error register User Device Token' + e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);

type Guest ={ 
  guestId: string;
}
type SharingArgs = {
  ownerId: string;
  sharing: Guest[]
}
export const createAwsAccess = (sharing: SharingArgs) => {
  try{
    const response = axios.post(
      'https://b14h7isg51.execute-api.eu-west-2.amazonaws.com/staging/bckp/access',{...sharing}, 
      {
      headers: {
      'Content-Type': 'application/json',
      }});
    console.log('response from create access object', response)
  }catch(e){
    console.log('===>error on create access object on aws')
    throw e
  }
}

export const deleteAwsAccess = async (sharing: SharingArgs) => {
  try {
    const response = await axios.delete(
      'https://b14h7isg51.execute-api.eu-west-2.amazonaws.com/staging/bckp/access', 
      {
      headers: {
        'Content-Type': 'application/json',
      },
      data: {...sharing}, // Include the data in the config object
      }
  );
    console.log('response from delete access object', response);
  } catch (e) {
    console.log('===>error on delete access object on aws');
    throw e;
  }
};
export type SharingItem = { 
  guestId: string;
}
export type Sharing = {
  ownerId: string;
  sharing: SharingItem[];
}
type UpdateUsersArgs = {
  users : ClientType[],
  sharingToCreate?: Sharing;
  SharingToDelete?: Sharing[];
}

export const updateUsersAction = createAsyncThunk<
  ClientType[] | undefined,
  UpdateUsersArgs
>(
  'clients_slice/updateUsersAction',
  async ( {users, sharingToCreate, SharingToDelete} , thunkAPI) => {
    try {
     console.log('update users', users, sharingToCreate, SharingToDelete);
      //http://127.0.0.1:5001/elisyanwealth/us-central1/updateUsers
        const response = await axios.post('https://updateusers-c5yieuvjca-uc.a.run.app', 
          { users: users}, {
        headers: {
        'Content-Type': 'application/json',
        }});
        console.log(' update user action response: ' , response)
        if(sharingToCreate && sharingToCreate.sharing.length> 0){ 
          try{
            const res = await createAwsAccess(sharingToCreate)
            console.log('createAwsAccess res', res)  
          }catch(err){
            console.log('delete err', err);
            throw err
          }
        }
        if(SharingToDelete){
          for(let i = 0 ; i < SharingToDelete.length ; i++){
          if(SharingToDelete[i].sharing.length> 0){
            try{
              const resDelete = await deleteAwsAccess(SharingToDelete[i])
              console.log('deleteAwsAccess res', resDelete)
            }catch(err){
              console.log('delete err', err);
              throw err
            }
            
          }
        }
        }
        return users;
    } catch (e) {
      console.log('-------------- Error Update user' + e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);


export const getAdvisorsAction = createAsyncThunk<
  ClientType[] | undefined,
  {business_name:string | undefined}
>(
  'clients_slice/getAdvisorsAction',
  async ({ business_name }, thunkAPI) => {
    try {
     // console.log('inputs', business_name);
      //http://127.0.0.1:5001/elisyanwealth/us-central1/getAdvisors
        const response = await axios.post('https://getadvisors-c5yieuvjca-uc.a.run.app', {business_name}, {
        headers: {
        'Content-Type': 'application/json',
        }});
        console.log(' get clients list' , response)
        return response.data.data;
    } catch (e) {
      console.log('-------------- Error register User Device Token' + e);
      return thunkAPI.rejectWithValue(e);
    }
  }
);
