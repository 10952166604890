import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppDispatch, RootState } from '../../store/store';
import { accessCodesStyles } from '../../assets/styles/accessCodesStyles';
import {  getAccessCodes, getBusinesses } from '../../store/actions/accessCodeActions';
import useCustomStyles from '../../hooks/useCustomStyles';
import {  useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import CustomTableRow from '../../components/CustomTableRow';
import { capitalizeFirstLetter, getCurrentDateFormatted } from '../../utils';
import CustomMultiSelect,{ Option } from '../../components/CustomMultiSelect';
import { customStyles } from '../../assets/styles/customStyles';

const AccessCodes: React.FC = () => {
  const theme = useSelector((state: RootState) => state.theme_slice.theme);
  const {user} = useSelector((state: RootState)=> state.auth_slice);
  const [selectedFilters, setSelectedFilters] = useState<Option[]>([]);
  const [selectedBusinessFilter, setSelectedBusinessFilter] = useState<Option[]>([]);
  const [showOwnerBusinesses, setShowOwnerBusinesses] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>()
 // const { t } = useSelector(selectTranslationState);
  const { accessCodes, businesses } = useSelector((state: RootState) => state.accessCode_slice);
  const classes = useCustomStyles(accessCodesStyles, theme);
  const classes1 = useCustomStyles(customStyles, theme)

  useEffect(()=>{
    if( user && user.business){
      dispatch(getAccessCodes({business_client: user?.business.name && user.business.name != 'elisyan' ? user?.business?.id : ''}));
    }
  },[]) 

  useEffect(()=>{

    if(selectedFilters.filter(option => option.value == 'referral-code' || option.value == 'licence-code').length > 0){
      setShowOwnerBusinesses(true)
    }else {
      setShowOwnerBusinesses(false)
    }

  },[selectedFilters]);
  
  useEffect(()=>{
    console.log('businesses', businesses)
    if(!businesses){
      dispatch(getBusinesses({type: undefined}));
    }
  },[])

  const isExpired = (date: string) => {
    return getCurrentDateFormatted() > date
  }

  // Define filter options
  const filterOptions: Option[] = [
    { label: 'Licence Code', value: 'licence-code' },
    { label: 'Referral Code', value: 'referral-code' },
    { label: 'Validated', value: 'validated' },
    { label: 'Active', value: 'active' },
  ];
  
  const filterBusinessOptions: Option[] = [
    
  ]

  businesses?.forEach(business => {
    filterBusinessOptions.push({label: business.name, value: business.id})
  })
  
  const filteredData = useMemo(() => {
    if (selectedFilters.length === 0 && selectedBusinessFilter.length === 0) return accessCodes || [];
  
    return (accessCodes || []).filter((code) => {
      const matchesFilters = selectedFilters.every((filter) => {
        if (filter.value === 'validated') return code.validated === true;
        if (filter.value === 'active') return code.validated === false;
        return code.codeType === filter.value;
      });
  
      const matchesBusinessFilter = selectedBusinessFilter.length === 0 || 
       (code.codeType == 'licence-code'? 
          selectedBusinessFilter.some((business) => business.value === code.business_client) 
          : selectedBusinessFilter.some((business) => business.label === code.for_business_client
        ));
  
      return matchesFilters && matchesBusinessFilter;
    });
  }, [accessCodes, selectedFilters, selectedBusinessFilter]);

  const handleBusinessFilterChange = (value: Option[]) => {
    // Ensure `value` doesn't contain conflicting options
    const newValue = value.filter(option => {
      if (
        (option.value === 'licence-code' && value.some(o => o.value === 'referral-code')) ||
        (option.value === 'referral-code' && value.some(o => o.value === 'licence-code')) ||
        (option.value === 'active' && value.some(o => o.value === 'validated')) ||
        (option.value === 'validated' && value.some(o => o.value === 'active'))
      ) {
        return false; // Exclude conflicting options
      }
      return true;
    });
  
    setSelectedFilters(newValue);
  };

  return (
    <div className={classes.container}>
      <div className={classes.tableContainer}>
        <h2 className={classes.header}>ASSIGN NEW LICENCES</h2>
      
       <div className={classes.buttonContainer} > 
      <CustomButton title={'Assign New Licence'} 
                    handleClick={()=> navigate('/generateLicence')} 
                    name={'Assign-New-Licence'} 
                    loading={false} /></div>
      </div>   
{ user?.business?.name == 'elisyan' && <div className={classes1.flexStart}>
      <CustomMultiSelect
        name="Code Type"
        value={selectedFilters}
        onChange={value => handleBusinessFilterChange(value)}
        options={filterOptions}
        //label='Filter by Type'
        placeholder='Filter by Type'
      />
      
      {showOwnerBusinesses && <CustomMultiSelect
        name="Business Client"
        value={selectedBusinessFilter}
        onChange={value => setSelectedBusinessFilter(value)}
        options={filterBusinessOptions}
       // label='Filter by Business Owner'
        placeholder='Filter by Business Owner'
      />}
      
      </div>}
      <table className={classes.table}>
        <thead>
          <CustomTableRow key={`#index-thead`} 
            items={['Type','Licence Access Code',
            'Expiry Date','Status','']} 
            index={0} isHeader={true}/>
        </thead>
        <tbody>
                {filteredData &&
            filteredData.map((code, index) => (
              <CustomTableRow key={`#index-${index}`} items={[capitalizeFirstLetter(code.role_type),code.codeType == 'referral-code'? `${code.prefix || ''}#${code.id}(${code.for_business_client})` : `${code.id}`, code.expire_at,isExpired(code.expire_at)? 'Expired': code.validated? 'Validated': 'Active']} index={index+1} link={`/accessCode/${code.id}`}/>
              
            ))}
        </tbody>
      </table>
     
    </div>
  );
};

export default AccessCodes;