import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const customStyles = (theme: Theme): { [key: string]: Record<string, CSSObject | string | number> } => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: theme.colorSchema.colors.mainBackground,
    boxShadow: `0px 2px 5px rgba(0, 0, 0, 0.1)`,
    //boxShadow: `0px 0px 10px 0px ${theme.colorSchema.colors.mainText}`,
    padding: '10px 20px',
    position: 'relative', // Important for positioning the dropdown menu
  },
  hamburgerIcon: {
    display: 'none',
    cursor: 'pointer',
    '@media (max-width: 768px)': {
      display: 'block',
    },
  },
  navigationList: {
    display: 'flex',
    alignItems: 'center',
    listStyle: 'none',
    padding: 0,
    
    margin: 0,
    gap: '10px',
    '@media (max-width: 768px)': {
      display: 'none',
      position: 'absolute',
      transition: 'display 0.3s ease, color 0.3s ease',
      top: '100%',
      left: 0,
      right: 0,
      flexDirection: 'column',
      backgroundColor: theme.colorSchema.colors.mainBackground,
      padding: '20px',
      boxShadow: `0px 4px 8px 0px ${theme.colorSchema.colors.secondaryText}`,
      borderRadius: '0 0 5px 5px',
    },
  },
  navigationListOpen: {
    display: 'flex !important' , // Show the menu on mobile when open
  },
  
  link: {
    cursor: 'pointer',
    padding: '10px 20px',
    textDecoration: 'initial',
    color: theme.colorSchema.colors.mainText,
    borderRadius: '5px',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.colorSchema.colors.secondaryBackground,
      color: theme.colorSchema.colors.secondaryText,
    },
    '&:active': {
      backgroundColor: theme.colorSchema.colors.primary,
      color: theme.colorSchema.colors.primary,
      textDecoration: 'underline'
    },
  },
  activeLink: {
    color: theme.colorSchema.colors.primary,
    textDecoration: 'underline',
  },
  logo: {
    display: 'flex',
    alignItems: 'center',
  },
  logoText: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.colorSchema.colors.mainText,
    justifyContent: 'space-between',
    transition: 'background-color 0.3s ease, color 0.3s ease',
    '&:hover': {
      backgroundColor: theme.colorSchema.colors.primary,
     // color: theme.colorSchema.colors.secondaryBackground,
      padding: '5px',
      borderRadius: '5px',
    },
  },
  logout: {
    color :theme.colorSchema.colors.mainText,
  }
});
