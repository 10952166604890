// src/assets/styles/AccessCodesStyles.ts

import { Theme } from '../../types';
import { CSSObject } from '@emotion/react';

export const accessCodesStyles = (theme: Theme): { [key: string]: Record<string, CSSObject | string | number> } => ({
  container: {
    padding: '40px',
  },
  header: {
    color: theme.colorSchema.colors.mainText,
    marginBottom: '20px',
    fontWeight: '500',
  },
  table: {
    width: '100%',
    borderSpacing: '0px 8px',
    borderCollapse: 'collapse',
  },
  tr: {
    //
    border: `1px solid ${theme.colorSchema.colors.thirdBackground}`,
    marginBottom: '2px',
  },
  th: {
    //theme.colorSchema.colors.thirdBackground
    background: '#E7E6E6',  
    padding: '12px',
    fontWeight: 'bold',
    // border: '1px solid #ccc',
    color:theme.colorSchema.colors.primary,
    cursor: 'pointer',
    textAlign: 'left',
    marginTop: '100px',     
    marginBottom: '10px', 
   // borderRadius: '5px', 
  },
  td: {
    background: theme.colorSchema.colors.mainBackground,
    padding: '10px',
    // border: '1px solid #ccc',
    cursor: 'pointer',
    textAlign: 'left',
    marginTop: '100px',     
    color: theme.colorSchema.colors.mainText,
    marginBottom: '8px',  },
  arrow: {
    textDecoration: 'underline',
  },
  buttonContainer: {
    width: '25%',
  },
  tableContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '5vh',
  }
});
