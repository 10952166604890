import React from 'react';
import useCustomStyles from '../hooks/useCustomStyles';
import { customStyles } from '../assets/styles/customeSelectStyles';
import { useSelector } from 'react-redux';
import { selectState } from '../store/slices/themeSlice';

// Define the props interface for the component
interface CustomSelectProps {
  name: string;
  value: string | undefined;
  // Accept an array of either strings or objects with `name` and `id` fields
  options: (string | { name: string; id: string })[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onBlure?: (event: React.FocusEvent<HTMLSelectElement>) => void; 
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  error?: string; 
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  name,
  value,
  options,
  onChange,
  onBlure,
  disabled = false,
  placeholder = "Select an option", // Default placeholder value
  label,
  error
}) => {
  const { theme } = useSelector(selectState);
  const classes = useCustomStyles(customStyles, theme);

  return (
    <div className={classes?.customSelectContainer}> {/* Add container wrapper */}
      {label && <label className={classes?.customSelectLabel}>{label}</label>} {/* Render label if provided */}
    <select
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlure}
      className={classes?.customSelect}
      disabled={disabled}
    >
      {/* Render the placeholder as the first option */}
      <option value="" disabled = {true}>
        {placeholder}
      </option>
      {options.map((option, index) => {
        // If option is a string, treat it as both the value and display text
        if (typeof option === 'string') {
          return (
            <option key={index} value={option}>
              {option}
            </option>
          );
        }

        // If option is an object, use `id` as the value and `name` as the display text
        return (
          <option key={index} value={option.id}>
            {option.name}
          </option>
        );
      })}
    </select>
      {/* Conditionally render error message */}
      {error && <p className={classes?.error}>{error}</p>}
    </div>
  );
};

export default CustomSelect;
