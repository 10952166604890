import { sendEmailVerification, signInWithEmailAndPassword, signOut, updateEmail } from "firebase/auth";
import { auth, firestore } from "../../Firebase";
import { createAsyncThunk } from '@reduxjs/toolkit';
import { doc, getDoc } from 'firebase/firestore';
import { BusinessesType } from "./accessCodeActions";
import { toast } from "react-toastify";

import { getAuth, updatePassword, EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";

interface UpdatePasswordArgs {
  currentPassword: string;
  newPassword: string;
}
interface UpdateProfileArgs {
  currentPassword: string;
  newPassword?: string;
  newEmail?: string;
}

export async function verifyUserEmail() {
  try {
    const auth = getAuth();
    const user = auth.currentUser; // or you can fetch any user by their UID
    if(user){
        const res = await sendEmailVerification(user)
         console.log('veriication link sent successfuly!.', res);
    }
  } catch (error) {
    console.error('Error sending verification link user email:', error);
  }
}

export async function updateProfileAdmin() {
  try {const auth = getAuth()
   const user = auth.currentUser
   if(user){
    const res = await axios.post('http://127.0.0.1:5001/elisyanwealth/us-central1/UpdateProfile',{uid: user.uid}, {headers: {
      'Content-Type': 'application/json',
    }})
    console.log('profile updated successfuly <email verified>', res)
   }
   
  }catch(error){
    console.log('error updating profile', error)
  }
} 

export const updateProfileAction = createAsyncThunk<{ id: string; email: string } | void, UpdateProfileArgs>(
  'auth_slice/updateProfile',
  async ({ currentPassword, newPassword, newEmail }, thunkAPI) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      return thunkAPI.rejectWithValue('No user is signed in.');
    }

    try {
      // Reauthenticate user
      const credential = EmailAuthProvider.credential(user.email as string, currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Step 1: Update password if provided
      if (newPassword) {
        await updatePassword(user, newPassword);
      }

      // Step 2: Update email if newEmail is provided
      if (newEmail && newEmail !== user.email) {
        await updateEmail(user, newEmail); // Update email to new email
        console.log('Email updated successfully!');
        
        // Send verification email to the new email
        await sendEmailVerification(user);
        console.log('Verification email sent successfully!');
        
        // Log out user after email update and verification email sent
        thunkAPI.dispatch(logout());
       // await signOut(auth);
        toast.info("A verification email has been sent. Please verify your email before logging in again.");
        
        // Return the updated user information
        return { id: user.uid, email: newEmail };
      }

      console.log('Profile updated successfully!');
      // If no email update was needed, return `void`
      return;
    } catch (error) {
      if (error instanceof FirebaseError) {
        console.log('Firebase-specific error:', error);
        switch (error.code) {
          case 'auth/invalid-credential':
            return thunkAPI.rejectWithValue('The current password is incorrect.');
          case 'auth/wrong-password':
          return thunkAPI.rejectWithValue('The current password is incorrect.');
          case 'auth/too-many-requests':
            return thunkAPI.rejectWithValue('Too many attempts. Please try again later.');
          case 'auth/weak-password':
            return thunkAPI.rejectWithValue('The new password is too weak. Please use a stronger password.');
          case 'auth/network-request-failed':
            return thunkAPI.rejectWithValue('Network error. Please check your internet connection.');
          case 'auth/requires-recent-login':
            return thunkAPI.rejectWithValue('Please log in again to update your profile.');
          case 'auth/email-already-in-use':
            return thunkAPI.rejectWithValue('This email has already been used.');
          default:
            return thunkAPI.rejectWithValue('An error occurred while updating your profile. Please try again.');
        }
      } else {
        console.log('Unexpected error during profile update:', error);
        return thunkAPI.rejectWithValue('An unexpected error occurred. Please try again.');
      }
    }
  }
);


export const updatePasswordAction = createAsyncThunk<void, UpdatePasswordArgs>(
  'auth_slice/updatePassword',
  async ({ currentPassword, newPassword }, thunkAPI) => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user) {
      return thunkAPI.rejectWithValue('No user is signed in.');
    }

    try {
     
      const credential = EmailAuthProvider.credential(user.email as string, currentPassword);
      await reauthenticateWithCredential(user, credential);

      await updatePassword(user, newPassword);
      console.log('Password updated successfully!');

    } catch (error) {
      if (error instanceof FirebaseError) {
        console.log('Firebase-specific error:', error.code);

        switch (error.code) {
          case 'auth/invalid-credential':
            return thunkAPI.rejectWithValue('The current password is incorrect.');
          case 'auth/too-many-requests':
            return thunkAPI.rejectWithValue('Too many attempts. Please try again later.');
          case 'auth/weak-password':
            return thunkAPI.rejectWithValue('The new password is too weak. Please use a stronger password.');
          case 'auth/network-request-failed':
            return thunkAPI.rejectWithValue('Network error. Please check your internet connection.');
          case 'auth/requires-recent-login':
            return thunkAPI.rejectWithValue('Please log in again to update your password.');
          default:
            return thunkAPI.rejectWithValue('An error occurred while updating the password. Please try again.');
        }
      } else {
        console.log('Unexpected error during password update:', error);
        return thunkAPI.rejectWithValue('An unexpected error occurred. Please try again.');
      }
    }
  }
);

  interface loginArgs {
    email: string;
    password: string;
  }
  
export  interface UserData {
    id: string;
    email: string;
    roles: string[];
    user_name: string;
    //user: UserCredential;
    token: string;
    profile_url: string | null;
    business: BusinessesType | null
  }

import { FirebaseError } from 'firebase/app';
import axios from "axios";
export const login = createAsyncThunk<UserData | undefined, loginArgs>(
  'auth_slice/login',
  async ({ email, password }, thunkAPI) => {
    try {
      // Sign in the user with Firebase Auth
      const response = await signInWithEmailAndPassword(auth, email, password);
       console.log('login response', response)
      // Check if the email is verified
    /*
       if (!response.user.emailVerified) {
        return thunkAPI.rejectWithValue('Email is not verified. Please check your inbox and verify your email.');
      }
*/
      // Reference to the Firestore document for the user
      const userDocRef = doc(firestore, 'users', response.user.uid);
      
      // Fetch the Firestore document data
      const userDoc = await getDoc(userDocRef);
      
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const roles = userData?.web_roles || [];
        const user_name = userData?.user_name || '';
        
        const businessRef = userData?.business_id;
        let business: BusinessesType | null = null;

        if (businessRef) {
          const businessDoc = await getDoc(businessRef);
          if (businessDoc.exists()) {
            const businessData = businessDoc.data() as BusinessesType;
            business = {
              id: businessDoc.id,
              type: businessData?.type || '',
              name: businessData?.name || '',
            };
          } else {
            console.error('No business document found in Firestore.');
          }
        }

        const combinedUserData: UserData = {
          id: response.user.uid,
          email: response.user.email || '',
          roles,
          user_name,
          token: response.user.refreshToken,
          profile_url: response.user.photoURL,
          business,
        };

        return combinedUserData;
      } else {
        console.error('No user document found in Firestore.');
        return thunkAPI.rejectWithValue('User document not found. Please contact admin.');
      }
    } catch (error) {
      console.log('Error during login:', error);

      if (error instanceof FirebaseError) {
        console.log('Firebase-specific error:', error.code);

        // Handle specific Firebase errors
        switch (error.code) {
          case 'auth/wrong-password':
            return thunkAPI.rejectWithValue('Incorrect password. Please try again.');
          case 'auth/user-not-found':
            return thunkAPI.rejectWithValue('No user found with this email.');
          case 'auth/too-many-requests':
            return thunkAPI.rejectWithValue('Too many login attempts. Please try again later.');
          case 'auth/network-request-failed':
            return thunkAPI.rejectWithValue('Network error. Please check your connection.');
          case 'auth/invalid-credential':
            return thunkAPI.rejectWithValue('Invalid credentials. Please verify your email and password.');
          default:
            return thunkAPI.rejectWithValue('Login failed due to an unknown error. Please try again later.');
        }
      } else {
        console.log('Unexpected error during login:', error);
        return thunkAPI.rejectWithValue('An unexpected error occurred. Please try again.');
      }
    }
  }
);



  export const logout = createAsyncThunk<string, void>(
    'auth/logout',
    async (_, thunkAPI) => {
      try {
        await signOut(auth);
        return 'Logout successful'; // Optional message on successful logout
      } catch (e) {
        console.error('Error during logout:', e);
        // Use thunkAPI to return a string error message
        toast.error("Server Error Please try again!");
        return thunkAPI.rejectWithValue('Failed to logout. Please try again.');
      }
    }
  );

 

  
  