// translationSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import en from '../../locales/en.json';
import fr from '../../locales/fr.json';
import { RootState } from '../store';

type Translation = typeof en | typeof fr;

interface TranslationState {
  language: string;
  t: Translation;
}

const translations: Record<string, Translation> = { en, fr };

const initialState: TranslationState = {
  language: 'en',
  t: translations.en,
};

const translationSlice = createSlice({
  name: 'translation',
  initialState,
  reducers: {
    toggleLanguage: (state) => {
      const newLanguage = state.language === 'en' ? 'fr' : 'en'; // Assuming you have multiple languages
      state.language = newLanguage;
      state.t = translations[newLanguage];
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      const newLanguage = action.payload;
      state.language = newLanguage;
      state.t = translations[newLanguage];
    },
  },
});

export const { toggleLanguage, setLanguage } = translationSlice.actions;
export const selectTranslationState = (state: RootState) => state.translation_slice;
export default translationSlice.reducer;
