
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useCustomStyles from '../../hooks/useCustomStyles';
//import { customStyles } from '../../assets/styles/notificationForm';
import { selectState } from '../../store/slices/themeSlice';
import { AppDispatch, RootState} from '../../store/store';
import { getAdvisorsAction, getClientsAction } from '../../store/actions/clientsActions';
import { ClientType } from '../../store/slices/clientsSlice';
import { useNavigate } from 'react-router-dom';
import CustomTableRow from '../../components/CustomTableRow';
import { capitalizeFirstLetter } from '../../utils';
import { accessCodesStyles } from '../../assets/styles/accessCodesStyles';


const Advisors = () => {
   
    const { theme } = useSelector(selectState);
    const classes = useCustomStyles(accessCodesStyles, theme);
    const {user} = useSelector((state: RootState)=> state.auth_slice);
    const {advisors} = useSelector((state: RootState)=> state.clients_slice);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    
    useEffect(() => { 
          dispatch(getAdvisorsAction({business_name: user?.business?.name}));
          dispatch(getClientsAction({business_name: user?.business?.name}));       
    }, [])
    useEffect(()=>{
        console.log('advisors',advisors)
    },[advisors])

    const handleAdvisorClick = (advisor: ClientType) => {
        // Navigate to client details screen with the client's ID as a parameter
        navigate(`/advisorDetails`, { state: { advisor } });
      };

  return (    
        <div className={classes.container}>
      <div className={classes.tableContainer}>
        <h2 className={classes.header}>MANAGE ADVISORS</h2>
      </div>   
      <table className={classes.table}>
        <thead>
          <CustomTableRow key={`#index-thead-advisor`} 
            items={['Name','Email',
            'Clients','Roles', '']} 
            index={0} isHeader={true}/>
        </thead>
        <tbody>
          {advisors &&
          advisors.map((advisor, index) => (
                 
          <CustomTableRow key={`#index-advisor-row-${index}`} 
              items={[
                capitalizeFirstLetter(advisor.user_name),
                advisor.email, 
                (advisor.clients && advisor.clients.length > 0)? `(${advisor.clients.length} ${advisor.clients.length > 1? 'Clients' : 'Client'})`: '',
                advisor.roles.join(',')
                ]} 
                index={index+1} 
                client={advisor} 
                handleNavigate={handleAdvisorClick}/>
        ))}
        </tbody>
      </table>
     
    </div>
  );
};

export default Advisors;
