// Default App Theme Schema
export const DefaultConfigFile = {
    name: 'elisyan',
    light: {
        colorSchema: {
            inputs: {
                secondary: {
                    background: '#F6F6F6',
                    text: '#720F83',
                    outline: '#720F83',
                    icon: '#720F83',
                },
                plus: {
                    background: ['#1D3AAB', '#880098'],
                    text: '#FFFFFF',
                    outline: 'transparent',
                    icon: '#FFFFFF',
                },
                primary: {
                    background: ['#FFFFFF', '#FFFFFF'],
                    text: '#00000',
                    outline: '#880098',
                    icon: '#FFFFFF',
                },
                },
            buttons: {
            secondary: {
                background: '#F6F6F6',
                text: '#FFFFFF',
                outline: '#720F83',
                icon: '#720F83',
            },
            plus: {
                background: ['#1D3AAB', '#880098'],
                text: '#FFFFFF',
                outline: 'transparent',
                icon: '#FFFFFF',
            },
            primary: {
                background: ['#1D3AAB', '#880098'],
                text: '#FFFFFF',
                outline: 'transparent',
                icon: '#FFFFFF',
            },
            },
            colors: {
            secondary: '#720F83',
            progressBarDashboard: ['#1D3AAB', '#880098'],
            secondaryBackground: '#F6F6F6',
            mainBackground: '#FFFFFF',
            profilePictureStroke: '#FFFFFF',
            text: '#720F83',
            mainText: '#000000',
            primary: '#720F83',
            thirdText: '#880098',
            secondaryText: '#720F83',
            inputBackground: '#FFFFFF',
            thirdBackground: '#E7E6E6',
            },
        },
       },
    dark: {
        colorSchema: {
            inputs: {
                secondary: {
                    background: '#F6F6F6',
                    text: '#720F83',
                    outline: '#720F83',
                    icon: '#720F83',
                },
                plus: {
                    background: ['#1D3AAB', '#880098'],
                    text: '#FFFFFF',
                    outline: 'transparent',
                    icon: '#FFFFFF',
                },
                primary: {
                    background: ['#FFFFFF', '#FFFFFF'],
                    text: '#720F83',
                    outline: '#880098',
                    icon: '#FFFFFF',
                },
                },
            buttons: {
            secondary: {
                background: '#F6F6F6',
                text: '#720F83',
                outline: '#720F83',
                icon: '#720F83',
            },
            plus: {
                background: ['#1D3AAB', '#880098'],
                text: '#FFFFFF',
                outline: 'transparent',
                icon: '#FFFFFF',
            },
            primary: {
                background: ['#1D3AAB', '#880098'],
                text: '#FFFFFF',
                outline: 'transparent',
                icon: '#FFFFFF',
            },
            },
            colors: {
            secondary: '#720F83',
            progressBarDashboard: ['#1D3AAB', '#880098'],
            secondaryBackground: '#061139',
            mainBackground:  '#061139',
            profilePictureStroke: '#FFFFFF',
            text: '#FFFFFF',
            mainText: '#FFFFFF',
            secondaryText: '#C24AD7',
            thirdText: '#880098',
            primary: '#720F83',
            inputBackground: '#FFFFFF',
            thirdBackground: '#E7E6E6',
            },
        },
    },
  };
  