import * as Yup from 'yup';

// Define your validation schema
const validationSchema = Yup.object({
  codeType: Yup.string().required('Code Type is required'),

  email: Yup.string().when('codeType', (codeType, schema) => {
    if (codeType[0] === 'invite-code') {
      return schema.required('Email is required for invite code').email('Must be a valid email');
    }
    return schema.notRequired();
  }),

  business_client: Yup.string().when('codeType', (codeType, schema) => {
    //console.log(' if (typeof codeType licence code Business client validations', codeType) 
    if (codeType[0] == 'licence-code' || codeType[0] === 'referral-code') {
       // console.log(' if (typeof codeType licence code Business client validations', codeType) 
        return schema.min(2,'Please select a Business Client').required('Business Client is required');
    }
    return schema.notRequired();
  }),  

  contract_ref: Yup.string().when('codeType', (codeType, schema) => {
    if (codeType[0] === 'licence-code') {
      return schema.required('Contract Reference is required').min(3, 'Contract reference is required for generating a licence');;
    }
    return schema.notRequired();
  }),

  expire_at: Yup.date().when('codeType', (codeType, schema) => {
    if (codeType[0] === 'referral-code') {
     // console.log(' expiry date validations', codeType)
      return schema.required('Expiry date is required for referral code').min(3,'Please Select an Expiry date');
    }
    return schema.notRequired();
  }),

  useLimit: Yup.number().when('codeType', (codeType, schema) => {
    if (codeType[0] === 'referral-code') {
      return schema.required('Use Limit is required').min(1, 'Use Limit must be at least 1');
    }
    return schema.notRequired();
  }),

  roles: Yup.array().when(['codeType', 'role_type'], ([codeType, role_type], schema) => {
    if (codeType == 'licence-code' && role_type == 'advisor') {
     // console.log(' roles validation', codeType)  
      return schema.min(1, 'At least one role is required for advisor').required('Roles are required');
    }
    return schema.notRequired();
  }),
  
  number_of_codes_to_generate: Yup.number().when(['contract_left_licences', 'codeType'],([contract_left_licences, codeType], schema)=> {
    console.log('from validations', contract_left_licences,codeType)
    if(codeType == 'licence-code' && contract_left_licences > 0){
      return schema.min(1,'1 is the minimum of licences to generate').max(contract_left_licences, `this contract has only ${contract_left_licences} codes left`).required('Please Add number of codes to generate');
    } 
    // Return the schema as-is if no condition is met
    return schema;
  })
});

export default validationSchema;
