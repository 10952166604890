import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { logout, updateProfileAction, updateProfileAdmin } from "../store/actions/authActions";
import { selectState, setError } from "../store/slices/authSlice";
import "../assets/styles/index.css";
import CustomButton from "../components/CustomButton";
import useCustomStyles from "../hooks/useCustomStyles";
import { toast } from "react-toastify";
import { profileStyles } from '../assets/styles/profileStyles';
import { customStyles } from "../assets/styles/customStyles";
import { ReactComponent as SopherProfileHeader } from '../assets/images/Header background HD.svg';
import { ReactComponent as ElisyanProfileHeader } from '../assets/images/Elisyan Header background HD.svg';
import { ReactComponent as SopherProfileIcon } from '../assets/images/Profile Icon.svg';
import { ReactComponent as ElisyanProfileIcon } from '../assets/images/Elisyan Profile Icon .svg';
import { ReactComponent as Logout } from '../assets/images/7124045_logout_icon.svg';
import CustomDisplayInput from "../components/CustomDisplayInput";
import { capitalizeFirstLetter } from "../utils";
import { applyActionCode, getAuth } from "firebase/auth";
import { useLocation } from "react-router-dom";
import { logoutTheme } from "../store/slices/themeSlice";
import ConfirmLogoutModal from "../components/ConfirmLogout";
import { logout as accessCodeLogout } from '../store/slices/accessCodeSlice';
import { selectTranslationState } from "../store/slices/languageSlice";

function ProfileView() {
  
  const dispatch = useDispatch<AppDispatch>();
  const { error, loading } = useSelector(selectState);
  const { user } = useSelector((state: RootState) => state.auth_slice)
  const { theme, activeTheme } = useSelector((state: RootState) => state.theme_slice);
  const classes = useCustomStyles(profileStyles, theme);
  const classes1 = useCustomStyles(customStyles, theme);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState<string | null>(null);
  const [newPasswordError, setNewPasswordError] = useState<string | null>(null);
  const [email, setEmail] = useState(user?.email || "");
  const {t} = useSelector(selectTranslationState)
  //const [userName, setUserName] = useState(user?.user_name || "");
  const [emailError, setEmailError] = useState<string | null>(null);
// const [userNameError, setUserNameError] = useState<string | null>(null);
  

    const auth = getAuth();
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const oobCode = query.get('oobCode');
    const mode = query.get('mode');

    useEffect(() => {
    if (mode === 'verifyEmail' && oobCode) {
        applyActionCode(auth, oobCode)
        .then(() => {
            console.log('Email verified successfully!');
            toast.success('Email verified successfully!')
            // Handle any post-verification UI updates or redirects
        })
        .catch((error) => {
            toast.error('Email verification failed:',error.message)
        });
    }
    }, [mode, oobCode, auth]);
    
   const handleEmailLink = async() => {
    await updateProfileAdmin()
   }

  const handleUpdateProfile = async () => {
    if (validate()) {
      try {
        await dispatch(updateProfileAction({
          currentPassword,
          newPassword: newPassword,
          newEmail: email !== user?.email ? email : undefined,
         // newUserName: userName !== user?.user_name ? userName : undefined,
        })).unwrap();
  
        toast.success("Profile updated successfully!");
      } catch (err) {
        console.error("Profile update error:", err);
      }
    }
  };


  const validate = () => {
    let isValid = true;
  
    // Email validation
    if (email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(email)) {
        setEmailError("Please enter a valid email address.");
        isValid = false;
      } else {
        setEmailError(null);
      }
    }
  
    // Password validation
    if (email || newPassword) {
      // Current password is required if changing email or password
      if (!currentPassword) {
        setCurrentPasswordError("Current password is required to change email or password.");
        isValid = false;
      } else {
        setCurrentPasswordError(null);
      }
    }
  
    if (newPassword) {
      if (newPassword.length < 6) {
        setNewPasswordError("New password must be at least 6 characters.");
        isValid = false;
      } else {
        setNewPasswordError(null);
      }
    } else {
      setNewPasswordError(null);  // Reset error if no new password is provided
    }
  
    return isValid;
  };
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const handleLogoutClick = () => {
    setIsLogoutModalOpen(true);
  };

  const confirmLogout = () => {
    setIsLogoutModalOpen(false);
    handleLogout(); // Call your logout function here
  };

  const cancelLogout = () => {
    setIsLogoutModalOpen(false);
  };
const handleLogout = () => {
  dispatch(logout())
  dispatch(accessCodeLogout())
 // dispatch(logoutTheme())
}

  return (
    <div className={`${classes?.container}`}>
     <div className={classes.profileHeader}> {(user?.business && ['sopher', 'sopher-test'].includes(user?.business.name))? <SopherProfileHeader  /> : <ElisyanProfileHeader />}</div>
     <div className={classes.profileBody}>
        <div className={classes.profileIcon}>{(user?.business && ['sopher', 'sopher-test'].includes(user?.business.name))?<SopherProfileIcon width={65} height={65} /> : <ElisyanProfileIcon width={65} height={65} />}</div>
        <div className={`${classes1.container} ${classes1.box2}`}>
        <div className={`${classes1.flexEnd} ${classes1.label2} ${classes1.clickable}`} onClick={()=> {handleLogoutClick()}}>{`${t[activeTheme].profileViewScreen.logout.label} `} <Logout width={30} height={30} /></div>
         
     {/* <button onClick={() => handleEmailLink()} >send link</button> */}

        <div className={classes1.titleContainer} >
          <h3 className={classes1.label2}> {t[activeTheme].profileViewScreen.personalInformationSection.title}</h3>
        </div>
          
          <CustomDisplayInput
              name={`user_name`} 
              label={t[activeTheme].profileViewScreen.personalInformationSection.userNameInput.label}
              value={user?.user_name && capitalizeFirstLetter(user?.user_name)}
              disabled
          />
          
          <CustomDisplayInput 
            name="role" 
            label={t[activeTheme].profileViewScreen.personalInformationSection.roleInput.label}
            value={user?.roles[0]} 
            disabled 
          />
         
         
          <div className={classes1.titleContainer} >
            <h3 className={classes1.label2}>{t[activeTheme].profileViewScreen.emailPasswordSection.title}</h3>
          </div>
        <CustomDisplayInput 
              label= {t[activeTheme].profileViewScreen.emailPasswordSection.emailInput.label}
              name={`email`} 
              value={email}
              onChange={(e) => { setEmail(e.target.value); setEmailError(null); }}
              error={emailError || ''}
          />
          <CustomDisplayInput 
              label= {t[activeTheme].profileViewScreen.emailPasswordSection.currentPasswordInput.label}
              onChange={(value) => { setCurrentPassword(value.target.value); setCurrentPasswordError(null); dispatch(setError(undefined))}}
              name={`password`} 
              type="password"
              value={currentPassword}
              error={currentPasswordError? currentPasswordError : ''}
              autoComplete="new-password"
          />
          <CustomDisplayInput 
              label= {t[activeTheme].profileViewScreen.emailPasswordSection.newPasswordInput.label}
              type="password"
              onChange={(value) => { setNewPassword(value.target.value); setNewPasswordError(null);dispatch(setError(undefined)) }}
              name={`newPassword`} 
              value={newPassword}
              error={newPasswordError ? newPasswordError : ''}
          />
        {error && <p className={classes1?.error}>{error.toString()}</p>}
         <CustomButton
                  title={t[activeTheme].profileViewScreen.emailPasswordSection.button.text}
                  name="save changes"
                  handleClick={handleUpdateProfile}
                  type="submit"
                  loading={loading}
                  disabled={loading}
                />
        
      
        </div>
    </div>
    <ConfirmLogoutModal
        isOpen={isLogoutModalOpen}
        onConfirm={confirmLogout}
        onCancel={cancelLogout}
      />
    </div>
  );
}

export default ProfileView;

