import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import useCustomStyles from '../hooks/useCustomStyles';
import { header2Styles } from '../assets/styles/header2';
import { selectTranslationState } from '../store/slices/languageSlice';

interface ConfirmLogoutModalProps {
    isOpen: boolean;
    onConfirm: () => void;
    onCancel: () => void;
  }

  const ConfirmLogoutModal: React.FC<ConfirmLogoutModalProps> = ({ isOpen, onConfirm, onCancel }) => {
  if (!isOpen) return null;
  const { theme, activeTheme } = useSelector((state: RootState) => state.theme_slice);
  const classes = useCustomStyles(header2Styles, theme);
  const {t} = useSelector(selectTranslationState)
  return (
    <div className={classes.overlay}>
      <div className={classes.modal}>
        <h2>{t[activeTheme].profileViewScreen.logout.confirmLogout.title} </h2>
       {/* <p>Are you sure you want to log out?</p>*/}
        <div className={classes.buttons}>
          <button onClick={onConfirm} className={classes.confirmButton}>{t[activeTheme].profileViewScreen.logout.confirmLogout.confirmButton.text}</button>
          <button onClick={onCancel} className={classes.cancelButton}>{t[activeTheme].profileViewScreen.logout.confirmLogout.cancelButton.text}</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmLogoutModal;
