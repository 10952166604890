import React, { useState } from "react";
import { useSelector } from "react-redux";
import { ReactComponent as OpenEye } from "../assets/images/eye_open_gray.svg";
import { ReactComponent as CloseEye } from "../assets/images/eye_close_gray.svg";

interface LogInSignUpInputProps {
  placeholder: string;
  value: string;
  onChangeText: (text: string) => void;
  secureTextEntry?: boolean;
  showToggle?: boolean;
  inCodeLogin?: boolean;
}

const LogInSignUpInput: React.FC<LogInSignUpInputProps> = ({
  placeholder,
  value,
  onChangeText,
  secureTextEntry = false,
  showToggle = false,
}) => {
  const [isPasswordVisible, setPasswordVisibility] = useState(false);

  const handlePasswordVisibility = () =>
    setPasswordVisibility(!isPasswordVisible);

  return (
    <div
      style={{
        borderWidth: "1.5px",
        width: "95%",
        borderRadius: "30px",
        borderColor: "#ccc",
        // minHeight: "47px",
        padding: "10px ",
        margin: "6px 0",
        display: "flex",
        alignItems: "center",
        borderStyle: "solid",
        // gap: "10px",
        // width: "307px",
        height: "20px,",
        maxHeight: "36px",
        marginBottom: "16px",
        marginTop: "16px",
        alignSelf: "center",
      }}
    >
      <input
        style={{
          fontSize: "16px",
          flex: 1,
          border: "none",
          outline: "none",
          color: "black",
        }}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChangeText(e.target.value)}
        type={secureTextEntry && !isPasswordVisible ? "password" : "text"}
      />
      {showToggle && (
        <button
          onClick={handlePasswordVisibility}
          style={{
            marginLeft: "auto",
            background: "none",
            border: "none",
            cursor: "pointer",
          }}
        >
          {isPasswordVisible ? (
            <div style={{ padding: 0 }}>
              <OpenEye height={20} width={20} />
            </div>
          ) : (
            <div style={{ padding: 0 }}>
              <CloseEye height={20} width={20} />
            </div>
          )}
        </button>
      )}
    </div>
  );
};

export default LogInSignUpInput;
